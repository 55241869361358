import { useMemo } from 'react';
import { useTheme } from '@mui/styles';
import isTooLight from './isTooLight';

export function useThemeChartColor() {
  const theme = useTheme();
  const defaultColor = theme.colors.blue;

  const color = useMemo(() => {
    return isTooLight(theme.palette.primary.main)
      ? isTooLight(theme.palette.secondary.main)
        ? defaultColor
        : theme.palette.secondary.main
      : theme.palette.primary.main;
  }, [theme.palette.primary.main, theme.palette.secondary.main]);

  return color;
}

export default useThemeChartColor;
