import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get } from 'lodash';

import {
  batchDeleteFilesAndFoldersAPI,
  batchDownloadFilesAndFoldersAPI,
  batchMoveOrganizationFilesAndFoldersAPI,
  generateGetUrlAPI,
} from '../../api';
import { addToastr, types } from '../toastr';

export const batchMoveOrganizationFilesAndFolders = createAsyncThunk(
  'uploads/moveOrganizationFilesAndFolders',
  async ({ items, parent, folder }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;

    const orgId = getState().organization.default.item_id;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const { folders, files } = await batchMoveOrganizationFilesAndFoldersAPI(
        orgId,
        parent,
        folder,
        items
      );

      dispatch(
        addToastr({
          title: `Moved ${items.length} ${
            items.length === 1 ? 'item' : 'items'
          }`,
          type: types.success,
        })
      );

      return { folders, files };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to move items',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchDownloadFilesAndFolders = createAsyncThunk(
  'uploads/batchDownloadFilesAndFolders',
  async (items, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;
    const orgId = getState().organization.default.item_id;

    // use items if provided, else use selected from bulkEdit
    const selected = items ?? getState().bulkEdit.selected;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const isSingleFile = selected.length === 1 && selected[0].type === 'file';

      const getUrl = isSingleFile
        ? await generateGetUrlAPI(orgId, selected[0].relation_id)
        : await batchDownloadFilesAndFoldersAPI(orgId, selected);

      const downloadLink = document.createElement('a');
      downloadLink.href = getUrl;
      downloadLink.style.display = 'none';
      const filename = getUrl.match('filename%3D%22(.*)%22')[1];
      downloadLink.setAttribute('download', filename ?? '');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to download files',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchDeleteFilesAndFolders = createAsyncThunk(
  'uploads/batchDeleteFilesAndFolders',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;
    const selected = getState().bulkEdit.selected;
    const orgId = getState().organization.default.item_id;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const fileAndFolderIds = selected.map((item) => item.relation_id);

      const { folders, files } = await batchDeleteFilesAndFoldersAPI(
        orgId,
        fileAndFolderIds
      );

      dispatch(
        addToastr({
          title: `Deleted ${fileAndFolderIds.length} ${
            fileAndFolderIds.length === 1 ? 'item' : 'items'
          }`,
          type: types.success,
        })
      );

      return { folders, files };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to download files',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
