import { isEqual, isEqualWith, isObject, sortBy } from 'lodash';

function comparator(value, other) {
  if (isObject(value) && isObject(other)) {
    return isEqual(value, other);
  }
}

export function isLayoutEqual(layout1, layout2) {
  return isEqualWith(sortBy(layout1, 'i'), sortBy(layout2, 'i'), comparator);
}

export default isLayoutEqual;
