import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEdgeOptionsAPI } from '../../api';
import { buildAsyncReducers } from '../thunkTemplate';
import { edge as initialState } from '../initialState';

const loadEdgeOptions = createAsyncThunk(
  'edge/getOptions',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().edge;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const { options, info } = await getEdgeOptionsAPI();
    return { options, info };
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'edge',
  initialState,
  reducers: {
    clearEdgeOptions: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [loadEdgeOptions]);
  },
});

const { clearEdgeOptions } = actions;

// Export the reducer, either as a default or named export
export { clearEdgeOptions, loadEdgeOptions };
export default reducer;
