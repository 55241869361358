import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { isEqual, get } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  getOrganizationDetailsAPI,
  postOrganizationDeviceAPI,
  putOrganizationDeviceAPI,
  deleteOrganizationDeviceAPI,
  batchDeleteOrganizationDevicesAPI,
  batchPutOrganizationDevicesAPI,
} from '../../api';
import { setTrends } from '../trends';

export const getOrganizationDevices = createAsyncThunk(
  'devices/getOrganizationDevices',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().devices;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());

    const { devices } = await getOrganizationDetailsAPI(
      organization.item_id,
      'devices',
      true
    );

    dispatch(hideLoading());

    return { devices };
  }
);

export const postOrganizationDevice = createAsyncThunk(
  'devices/postOrganizationDevice',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        devices: prevDevices,
      } = getState().devices;
      const { trends: prevTrends } = getState().trends;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const { device, trends } = await postOrganizationDeviceAPI(
        orgId,
        credId,
        data
      );
      dispatch(
        addToastr({
          title: 'Device added!',
          type: types.success,
          message: `Added ${device.name}.`,
        })
      );

      const devices = [...prevDevices, device];

      dispatch(setTrends([...prevTrends, ...trends]));

      return { devices };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add Device',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationDevice = createAsyncThunk(
  'devices/putOrganizationDevice',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        devices: prevDevices,
      } = getState().devices;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const updated = await putOrganizationDeviceAPI(orgId, credId, data);
      dispatch(
        addToastr({
          title: 'Device updated!',
          type: types.success,
          message: `Updated ${updated.name}.`,
        })
      );

      const devices = prevDevices.map((dev) =>
        dev.item_id === data.item_id && dev.relation_id === data.relation_id
          ? updated
          : dev
      );

      return { devices };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Device',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationDevice = createAsyncThunk(
  'devices/deleteOrganizationDevice',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        devices: prevDevices,
      } = getState().devices;
      const { trends: prevTrends } = getState().trends;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const deleted = await deleteOrganizationDeviceAPI(
        orgId,
        credId,
        data.item_id
      );
      dispatch(
        addToastr({
          title: 'Device removed!',
          type: types.success,
          message: get(deleted, 'name'),
        })
      );

      const devices = prevDevices.filter(
        (dev) =>
          !(
            dev.item_id === deleted.item_id &&
            dev.relation_id === deleted.relation_id
          )
      );

      const trends = prevTrends.filter(
        (trend) =>
          !(
            trend.parent === deleted.item_id &&
            trend.relation_id === deleted.relation_id
          )
      );
      dispatch(setTrends(trends));

      return { devices };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to remove Device',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchDeleteOrganizationDevices = createAsyncThunk(
  'devices/batchDeleteOrganizationDevices',
  async (_, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        devices: initialDevices,
      } = getState().devices;
      const { trends: prevTrends } = getState().trends;

      const selected = getState().bulkEdit.selected;

      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const deleted = await batchDeleteOrganizationDevicesAPI(
        organization.item_id,
        selected
      );
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Deleted ${deleted.length} ${
            deleted.length > 1 ? 'devices' : 'device'
          } from ${organization.name}.`,
        })
      );

      const deletedIds = deleted.map(
        (device) => `${device.item_id}|${device.relation_id}`
      );
      const devices = initialDevices.filter(
        (device) =>
          !deletedIds.includes(`${device.item_id}|${device.relation_id}`)
      );

      const trends = prevTrends.filter(
        (trend) => !deletedIds.includes(`${trend.parent}|${trend.relation_id}`)
      );
      dispatch(setTrends(trends));

      return { devices };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete Devices',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchPutOrganizationDevices = createAsyncThunk(
  'devices/batchPutOrganizationDevices',
  async ({ device_updates }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        devices: initialDevices,
      } = getState().devices;

      const org_id = getState().organization.default.item_id;
      const orgName = getState().organization.default.name;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updated = await batchPutOrganizationDevicesAPI(
        org_id,
        device_updates
      );
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Updated ${updated.length} ${
            updated.length > 1 ? 'devices' : 'device'
          } for ${orgName}.`,
        })
      );

      const updatedIds = updated.map(
        (device) => `${device.item_id}|${device.relation_id}`
      );
      const unchangedDevices = initialDevices.filter(
        (device) =>
          !updatedIds.includes(`${device.item_id}|${device.relation_id}`)
      );
      const devices = [...unchangedDevices, ...updated];

      return { devices };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to update Devices',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
