import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { addToastr, types } from '../toastr';
import {
  getOrganizationAppsAPI,
  putOrganizationAppAPI,
  postOrganizationAppAPI,
  deleteOrganizationAppAPI,
  batchPutSiteAppsAPI,
} from '../../api';

export const getOrganizationApps = createAsyncThunk(
  'apps/getOrganizationApps',
  async (org, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().apps;
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }
      dispatch(showLoading());
      const { org_apps, site_apps } = await getOrganizationAppsAPI(org.item_id);
      return { org_apps, site_apps };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationApp = createAsyncThunk(
  'organization/putOrganizationApp',
  async (appItem, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().apps;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const { item_id: orgId, relation_id: appId } = appItem;

      dispatch(showLoading());
      const org_apps = await putOrganizationAppAPI(orgId, appItem);

      dispatch(
        addToastr({
          title: 'App updated!',
          type: types.success,
          message: get(appItem, 'name'),
        })
      );

      return { org_apps };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update App',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const postOrganizationApp = createAsyncThunk(
  'organization/postOrganizationApp',
  async (appItem, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().apps;
      const orgId = getState().organization.default.item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const { org_apps, site_apps } = await postOrganizationAppAPI(
        orgId,
        appItem
      );

      dispatch(
        addToastr({
          title: 'App added to Organization!',
          type: types.success,
          message: get(appItem, 'name'),
        })
      );

      return { org_apps, site_apps };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add App',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationApp = createAsyncThunk(
  'organization/deleteOrganizationApp',
  async (appItem, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().apps;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const { item_id: orgId, relation_id: appId } = appItem;

      dispatch(showLoading());
      const org_apps = await deleteOrganizationAppAPI(orgId, appId);

      dispatch(
        addToastr({
          title: 'App removed from Organization!',
          type: types.success,
          message: get(appItem, 'name'),
        })
      );

      return { org_apps };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to remove App',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchPutSiteApps = createAsyncThunk(
  'organization/batchPutSiteApps',
  async (appItems, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().apps;
      const orgId = getState().organization.default.item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const site_apps = await batchPutSiteAppsAPI(orgId, appItems);

      dispatch(
        addToastr({
          title: 'Apps updated!',
          type: types.success,
        })
      );

      return { site_apps };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Apps',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);
