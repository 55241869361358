import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { trendCharts as initialState } from '../initialState';

import {
  getOrganizationTrendCharts,
  postOrganizationTrendChart,
  deleteOrganizationTrendChart,
} from './_trendCharts';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'trendCharts',
  initialState,
  reducers: {
    clearTrendCharts: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationTrendCharts,
      postOrganizationTrendChart,
      deleteOrganizationTrendChart,
    ]);
  },
});

// Export the reducer, either as a default or named export
const { clearTrendCharts } = actions;
export {
  clearTrendCharts,
  getOrganizationTrendCharts,
  postOrganizationTrendChart,
  deleteOrganizationTrendChart,
};
export default reducer;
