import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, omit } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  postDashboardWidgetAPI,
  putDashboardWidgetAPI,
  deleteDashboardWidgetAPI,
} from '../../api';
import { getDashboardWidgetData } from './_data';
import { findFirstAvailablePosition } from '../../helpers/dashboards';
import {
  setDashboardData,
  setDashboardWidgetData,
  setSelectedDashboard,
} from '.';

export const postDashboardWidget = createAsyncThunk(
  'dashboards/postDashboardWidget',
  async (
    { dashboardId, groupId, data, duplicateId = '', isWidgetGroup = false },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const orgId = getState().organization.default.item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const initialDashboard = initialDashboards[dashboardId];
      const initialLayout = groupId
        ? initialDashboard.widget_groups.find((g) => g.relation_id === groupId)
            .layout
        : initialDashboard.layout;

      const duplicateLayout = duplicateId
        ? initialLayout.find((l) => l.i === duplicateId)
        : {};

      const newWidgetWidth = duplicateLayout.w ?? 12;
      const newWidgetHeight = duplicateLayout.h ?? 6;
      const numCols = 24;

      const { x, y } = findFirstAvailablePosition(
        initialLayout,
        newWidgetWidth,
        newWidgetHeight,
        numCols
      );

      const widgetLayout = {
        x: x,
        y: y,
        w: newWidgetWidth,
        h: newWidgetHeight,
      };

      const { dashboard, widget } = await postDashboardWidgetAPI(
        orgId,
        dashboardId,
        data,
        widgetLayout,
        groupId,
        isWidgetGroup
      );
      dispatch(
        addToastr({
          title: `Widget ${isWidgetGroup ? 'group ' : ''}added!`,
          type: types.success,
          // message: `Added ${widget.name} to ${dashboard.name}.`,
        })
      );

      dispatch(setSelectedDashboard(dashboard));

      if (!isWidgetGroup) {
        if (duplicateId) {
          const copyWidgetData =
            getState().dashboards.data[dashboardId][duplicateId];
          dispatch(
            setDashboardWidgetData({
              dashboardId,
              widgetId: widget.relation_id,
              data: copyWidgetData,
            })
          );
        } else {
          dispatch(getDashboardWidgetData({ dashboardId, widget }));
        }
      }

      return {
        dashboards: {
          ...initialDashboards,
          [dashboard.relation_id]: dashboard,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: `Failed to add widget ${isWidgetGroup ? 'group' : ''}`,
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putDashboardWidget = createAsyncThunk(
  'dashboards/putDashboardWidget',
  async (
    { dashboardId, data, isWidgetGroup = false },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const orgId = getState().organization.default.item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updated = await putDashboardWidgetAPI(
        orgId,
        dashboardId,
        data,
        isWidgetGroup
      );
      dispatch(
        addToastr({
          title: `Widget ${isWidgetGroup ? 'group ' : ''}updated!`,
          type: types.success,
          // message: `Updated ${data.name} in ${updated.name}.`,
        })
      );
      // dispatch(getDashboardWidgetData({ dashboardId, widget: data }));

      return {
        dashboards: {
          ...initialDashboards,
          [updated.relation_id]: updated,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: `Failed to update widget ${isWidgetGroup ? 'group' : ''}`,
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteDashboardWidget = createAsyncThunk(
  'dashboards/deleteDashboardWidget',
  async (
    { dashboardId, data, isWidgetGroup = false },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const initialDashboardData =
        getState().dashboards.data[dashboardId] ?? [];
      const orgId = getState().organization.default.item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const updated = await deleteDashboardWidgetAPI(
        orgId,
        dashboardId,
        data.relation_id,
        isWidgetGroup
      );
      dispatch(
        addToastr({
          title: `Widget ${isWidgetGroup ? 'group ' : ''}deleted!`,
          type: types.success,
          // message: `Removed ${data.name} from ${updated.name}.`,
        })
      );

      const dashboards = {
        ...initialDashboards,
        [updated.relation_id]: updated,
      };

      dispatch(setSelectedDashboard(updated));

      if (isWidgetGroup) {
        const widgetIds = data.layout.map((l) => l.i);
        const dashboardData = omit(initialDashboardData, widgetIds);
        dispatch(setDashboardData(dashboardId, dashboardData));
      } else {
        const { [data.relation_id]: _, ...dashboardData } =
          initialDashboardData;
        dispatch(setDashboardData(dashboardId, dashboardData));
      }

      return { dashboards };
    } catch (err) {
      dispatch(
        addToastr({
          title: `Failed to delete widget ${isWidgetGroup ? 'group' : ''}`,
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);
