import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { isEqual, find, get, omit } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  deleteOrganizationScheduleAPI,
  forceOrganizationSchedulePollAPI,
  getOrganizationSchedulesAPI,
  putOrganizationScheduleAPI,
  postOrganizationScheduleAPI,
  batchDuplicateOrganizationSchedulesAPI,
  batchDeleteOrganizationSchedulesAPI,
} from '../../api';

const postOrganizationSchedule = createAsyncThunk(
  'schedules/postOrganizationSchedule',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        schedules: initialSchedules,
        scheduleResources: initialScheduleResources,
        scheduleDevices: initialScheduleDevices,
      } = getState().schedules;
      const { default: organization } = getState().organization;
      const sites = getState().sites.sites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const {
        schedule: added,
        schedule_resources,
        schedule_devices,
      } = await postOrganizationScheduleAPI(organization.item_id, data);

      const addedTo = data.site
        ? find(sites, ['relation_id', data.site]).name
        : organization.name;
      dispatch(
        addToastr({
          title: 'Connection added!',
          type: types.success,
          message: `Added ${data.name} to ${addedTo}.`,
        })
      );

      const schedules = initialSchedules.concat(added);

      const scheduleResources = {
        ...initialScheduleResources,
        [added.relation_id]: schedule_resources,
      };
      const scheduleDevices = {
        ...initialScheduleDevices,
        [added.relation_id]: schedule_devices,
      };

      return { schedules, scheduleResources, scheduleDevices };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to add Connection',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getOrganizationSchedules = createAsyncThunk(
  'schedules/getOrganizationSchedules',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().schedules;
    const organization = org || getState().organization.default;
    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());

    const schedules = await getOrganizationSchedulesAPI(organization.item_id);

    dispatch(hideLoading());

    return { schedules };
  }
);

const forceOrganizationSchedulePoll = createAsyncThunk(
  'schedules/forceOrganizationSchedulePoll',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().schedules;
    const organization = getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());
    await forceOrganizationSchedulePollAPI(organization.item_id);
    dispatch(hideLoading());

    return {};
  }
);

const putOrganizationSchedule = createAsyncThunk(
  'schedules/putOrganizationSchedule',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        schedules: initialSchedules,
        scheduleResources: initialScheduleResources,
        scheduleDevices: initialScheduleDevices,
        selected: initialSelected,
      } = getState().schedules;
      const { default: organization } = getState().organization;
      const sites = getState().sites.sites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const {
        schedule: updated,
        schedule_resources,
        schedule_devices,
      } = await putOrganizationScheduleAPI(data.item_id, data);

      const updatedFor = data.site
        ? find(sites, ['relation_id', data.site]).name
        : organization.name;
      dispatch(
        addToastr({
          title: 'Connection updated!',
          type: types.success,
          message: `Updated ${data.name} for ${updatedFor}.`,
        })
      );

      const schedules = initialSchedules.map((sched) =>
        sched.relation_id === updated.relation_id ? updated : sched
      );

      const scheduleResources = {
        ...initialScheduleResources,
        [updated.relation_id]: schedule_resources,
      };
      const scheduleDevices = {
        ...initialScheduleDevices,
        [updated.relation_id]: schedule_devices,
      };
      const selected =
        initialSelected.relation_id === updated.relation_id
          ? { ...updated }
          : { ...initialSelected };

      return { schedules, scheduleResources, scheduleDevices, selected };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to updated Connection',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteOrganizationSchedule = createAsyncThunk(
  'schedules/deleteOrganizationSchedule',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        schedules: initialSchedules,
        exceptionEvents: initialEvents,
        scheduleResources: initialScheduleResources,
        scheduleDevices: initialScheduleDevices,
        selected: initialSelected,
      } = getState().schedules;
      const { default: organization } = getState().organization;
      const sites = getState().sites.sites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const deleted = await deleteOrganizationScheduleAPI(
        data.item_id,
        data.relation_id
      );
      const deletedFrom = data.site
        ? find(sites, ['relation_id', data.site]).name
        : organization.name;
      dispatch(
        addToastr({
          title: 'Connection removed!',
          type: types.success,
          message: `Deleted ${data.name} from ${deletedFrom}.`,
        })
      );

      const schedules = initialSchedules.filter(
        (sched) => sched.relation_id !== deleted.relation_id
      );
      const exceptionEvents = initialEvents.filter(
        (event) => event.item_id !== deleted.relation_id
      );
      const scheduleResources = omit(initialScheduleResources, [
        deleted.relation_id,
      ]);
      const scheduleDevices = omit(initialScheduleDevices, [
        deleted.relation_id,
      ]);

      const selected =
        initialSelected.relation_id === deleted.relation_id
          ? {}
          : { ...initialSelected };

      return {
        schedules,
        exceptionEvents,
        scheduleResources,
        scheduleDevices,
        selected,
      };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to remove Connection',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

const batchDuplicateOrganizationSchedules = createAsyncThunk(
  'schedules/batchDuplicateOrganizationSchedules',
  async (payload, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        schedules: initialSchedules,
        scheduleResources: initialScheduleResources,
        scheduleDevices: initialScheduleDevices,
      } = getState().schedules;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const selected = payload || getState().bulkEdit.selected;
      const organization = getState().organization.default;
      const selectedSiteName = getState().sites.selected?.name;

      const { item_id: orgId, name: orgName } = organization;
      const scheduleIds = selected.map((sched) => sched.relation_id);

      dispatch(showLoading());
      const {
        schedules: added,
        schedule_resources,
        schedule_devices,
      } = await batchDuplicateOrganizationSchedulesAPI(orgId, scheduleIds);

      const addedTo = selectedSiteName || orgName;
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Duplicated ${added.length} ${
            added.length > 1 ? 'connections' : 'connection'
          } for ${addedTo}.`,
        })
      );

      const schedules = [...initialSchedules, ...added];
      const scheduleResources = {
        ...initialScheduleResources,
        ...schedule_resources,
      };
      const scheduleDevices = {
        ...initialScheduleDevices,
        ...schedule_devices,
      };

      return {
        schedules,
        scheduleResources,
        scheduleDevices,
      };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to duplicate Connections',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

const batchDeleteOrganizationSchedules = createAsyncThunk(
  'schedules/batchDeleteOrganizationSchedules',
  async (_, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        schedules: initialSchedules,
        exceptionEvents: initialEvents,
        scheduleResources: initialScheduleResources,
        scheduleDevices: initialScheduleDevices,
      } = getState().schedules;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const selected = getState().bulkEdit.selected;
      const organization = getState().organization.default;
      const selectedSiteName = getState().sites.selected?.name;

      const { item_id: orgId, name: orgName } = organization;
      const scheduleIds = selected.map((sched) => sched.relation_id);

      dispatch(showLoading());
      const deleted = await batchDeleteOrganizationSchedulesAPI(
        orgId,
        scheduleIds
      );

      const deletedFrom = selectedSiteName || orgName;
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Deleted ${deleted.length} ${
            deleted.length > 1 ? 'connections' : 'connection'
          } from ${deletedFrom}.`,
        })
      );

      const deletedIds = deleted.map((sched) => sched.relation_id);

      const schedules = initialSchedules.filter(
        (sched) => !deletedIds.includes(sched.relation_id)
      );
      const exceptionEvents = initialEvents.filter(
        (event) => !deletedIds.includes(event.item_id)
      );
      const scheduleResources = omit(initialScheduleResources, deletedIds);
      const scheduleDevices = omit(initialScheduleDevices, deletedIds);

      return {
        schedules,
        exceptionEvents,
        scheduleResources,
        scheduleDevices,
      };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete Connections',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  postOrganizationSchedule,
  getOrganizationSchedules,
  forceOrganizationSchedulePoll,
  putOrganizationSchedule,
  deleteOrganizationSchedule,
  batchDeleteOrganizationSchedules,
  batchDuplicateOrganizationSchedules,
};
