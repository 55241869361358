import http from 'axios';
import { Auth } from 'aws-amplify';

const setAuthHeader = async (config) =>
  await Auth.currentSession()
    .then((session) => {
      // User is logged in. Set auth header on all requests
      // config.headers.Authorization = session.accessToken.jwtToken;
      config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
      return Promise.resolve(config);
    })
    .catch((err) => {
      // No logged-in user: don't set auth header
      return Promise.resolve(config);
    });

const auth = http.create({
  baseURL: `${process.env.REACT_APP_AUTH_API}auth/v1`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const web = http.create({
  baseURL: `${process.env.REACT_APP_WEB_API}web/v1`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

web.interceptors.request.use(async function (config) {
  return await setAuthHeader(config);
});

export { auth };
export default web;
