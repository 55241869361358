export const findFirstAvailablePosition = (
  layout,
  newWidgetWidth,
  newWidgetHeight,
  numCols
) => {
  for (
    let y = 0;
    y <= layout.reduce((max, item) => Math.max(max, item.y + item.h), 0);
    y++
  ) {
    for (let x = 0; x < numCols; x++) {
      let spaceIsAvailable = true;

      for (const item of layout) {
        if (
          x < item.x + item.w &&
          x + newWidgetWidth > item.x &&
          y < item.y + item.h &&
          y + newWidgetHeight > item.y
        ) {
          spaceIsAvailable = false;
          break;
        }
      }

      if (spaceIsAvailable) {
        return { x, y };
      }
    }
  }

  return {
    x: 0,
    y: layout.reduce((max, item) => Math.max(max, item.y + item.h), 0),
  }; // Fallback to placing it below the last item
};

export default findFirstAvailablePosition;
