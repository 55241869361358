import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  getOrganizationScheduleDevicesAPI,
  getOrganizationScheduleEventsAPI,
  getOrganizationScheduleResourcesAPI,
} from '../../api';

import {
  setExceptionEvents,
  setScheduleDevices,
  setScheduleResources,
} from './index';

const getOrganizationScheduleResources = createAsyncThunk(
  'schedules/getOrganizationScheduleResources',
  async (org, { dispatch, getState }) => {
    const organization = org || getState().organization.default;

    const orgId = organization?.item_id;
    if (!orgId) return;

    dispatch(showLoading());
    const resources = await getOrganizationScheduleResourcesAPI(orgId);
    dispatch(hideLoading());

    dispatch(setScheduleResources(resources));
  }
);

const getOrganizationScheduleDevices = createAsyncThunk(
  'schedules/getOrganizationScheduleDevices',
  async (org, { dispatch, getState }) => {
    const organization = org || getState().organization.default;

    const orgId = organization?.item_id;
    if (!orgId) return;

    dispatch(showLoading());
    const devices = await getOrganizationScheduleDevicesAPI(orgId);

    dispatch(hideLoading());
    dispatch(setScheduleDevices(devices));
  }
);

const getOrganizationScheduleEvents = createAsyncThunk(
  'schedules/getOrganizationScheduleEvents',
  async (org, { dispatch, getState }) => {
    const organization = org || getState().organization.default;

    const orgId = organization?.item_id;
    if (!orgId) return;

    dispatch(showLoading());
    const exceptionEvents = await getOrganizationScheduleEventsAPI(orgId);

    dispatch(hideLoading());
    dispatch(setExceptionEvents(exceptionEvents));
  }
);

export {
  getOrganizationScheduleResources,
  getOrganizationScheduleDevices,
  getOrganizationScheduleEvents,
};
