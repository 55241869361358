import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { serviceSites as initialState } from '../initialState';

import {
  getOrganizationServiceSites,
  batchDeleteOrganizationServiceSites,
  postOrganizationServiceSite,
  putOrganizationServiceSite,
  deleteOrganizationServiceSite,
} from './_serviceSites';

const { reducer, actions } = createSlice({
  name: 'serviceSites',
  initialState,
  reducers: {
    clearServiceSites: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationServiceSites,
      batchDeleteOrganizationServiceSites,
      postOrganizationServiceSite,
      putOrganizationServiceSite,
      deleteOrganizationServiceSite,
    ]);
  },
});

// Export the reducer, either as a default or named export
const { clearServiceSites } = actions;
export {
  clearServiceSites,
  getOrganizationServiceSites,
  batchDeleteOrganizationServiceSites,
  postOrganizationServiceSite,
  putOrganizationServiceSite,
  deleteOrganizationServiceSite,
};
export default reducer;
