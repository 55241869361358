import { createSlice } from '@reduxjs/toolkit';
import { appSettings as initialState } from '../initialState';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setSplashPage(state, action) {
      state.splashPage = action.payload;
    },
    setOrgRedirect(state, action) {
      state.orgRedirect = action.payload;
    },
  },
});

// Export the reducer, either as a default or named export
const { setSplashPage, setOrgRedirect } = actions;
export { setSplashPage, setOrgRedirect };
export default reducer;
