import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get } from 'lodash';

/** AMPLIFY **/
import { Amplify, Auth } from 'aws-amplify';

/** REDUX **/
import { buildAsyncReducers } from '../thunkTemplate';
import { auth as initialState } from '../initialState';
import { setUser } from '../user';
import { addToastr, types } from '../toastr';
import { clearAppStore } from './_clear';
import { setSplashPage } from '../appSettings';
import { checkAccountReset } from '../../api';
import ROUTES from '../../constants/routes';

export const defaultAmplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
    clientMetadata: { appName: 'management' },
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: `${process.env.REACT_APP_USER_POOL_DOMAIN}.auth.${process.env.REACT_APP_REGION}.amazoncognito.com`,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: `https://${window.location.host}/workspace`,
      redirectSignOut: `https://${window.location.host}/account/logout`,
      responseType: 'code',
    },
  },
};

export const configureAmplifyAuth = (payload = defaultAmplifyConfig) =>
  Amplify.configure(payload);

const userState = async (user) => ({
  user,
  authenticated: Boolean(user),
  currentSession: await Auth.currentSession(),
});

const unauthenticatedPaths = [
  '/account',
  '/login',
  '/logout',
  '/terms',
  '/privacy',
];

export const isUnauthenticatedPath = (path) =>
  unauthenticatedPaths.some((str) => path.includes(str));

const configure = createAsyncThunk(
  'auth/configure',
  async (payload, { getState, dispatch }) => {
    dispatch(setSplashPage(true));
    configureAmplifyAuth(payload || defaultAmplifyConfig);

    let user = initialState.user;
    try {
      const { pathname } = getState().router.location;
      if (
        !isUnauthenticatedPath(pathname) ||
        pathname.includes('/invite-code')
      ) {
        user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        // set auth state early for determining auth vs. unauth routing
        await dispatch(setAuth(await userState(user)));

        await dispatch(setUser(user));
        return await userState(user);
      }
    } catch (err) {
      if (err !== 'The user is not authenticated') console.error(err);
      dispatch(push(ROUTES.UNAUTH.LOGIN));
    } finally {
      dispatch(setSplashPage(false));
    }
  }
);

const login = createAsyncThunk(
  'auth/login',
  async (payload, { getState, dispatch }) => {
    let { email, password } = payload;
    email = email.toLowerCase();
    let user = initialState.user;

    try {
      dispatch(setSplashPage(true));
      dispatch(showLoading());

      const resetResult = await checkAccountReset(email);
      const resetRequired = get(resetResult, 'reset', false);

      if (resetRequired) {
        console.info(`Password reset required: ${email}`);
        dispatch(
          addToastr({
            title: 'Password Reset Required',
            type: types.warning,
            message:
              'An email has been sent with instructions on resetting your account password.',
          })
        );
      } else {
        user = await Auth.signIn({
          username: email,
          password,
          validationData: { appName: 'management' }, // clientMetadata is omitted for USER_PASSWORD_AUTH signIn
        });

        // set auth state early for determining auth vs. unauth routing
        await dispatch(setAuth(await userState(user)));

        await dispatch(setUser(user));

        // Prevent redirect to /workspace if setUser() redirected to /account/invite-code
        const { pathname } = await getState().router.location;
        if (!pathname.includes('/account/invite-code')) {
          dispatch(push(ROUTES.AUTH.WORKSPACE));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(push(ROUTES.UNAUTH.LOGIN));
      dispatch(
        addToastr({
          title: 'Whoops! Looks like there is an issue.',
          type: types.error,
          message: error.message ? [error.message] : [error],
        })
      );
    } finally {
      dispatch(setSplashPage(false));
      dispatch(hideLoading());
    }

    // return await userState(user);
  }
);

const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  const user = initialState.user;
  try {
    // Start the progressbar
    dispatch(showLoading());

    await Auth.signOut();
    await dispatch(clearAppStore());
    dispatch(push(ROUTES.UNAUTH.LOGIN));

    // Stop the progressbar
    dispatch(hideLoading());

    // Redirect to logout and then back to login
    // window.location.href = defaultAmplifyConfig.Auth.oauth.redirectSignOut;
  } catch (err) {
    console.error(err);
    dispatch(push(ROUTES.UNAUTH.LOGIN));
  } finally {
    dispatch(setSplashPage(false));
  }
  return await userState(user);
});

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [configure, login, logout]);
  },
});

const { setAuth } = actions;

// Export the reducer, either as a default or named export
export default reducer;
export { configure, login, logout };
