import { createAsyncThunk } from '@reduxjs/toolkit';
import { find, get, isEqual } from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { addToastr, types } from '../toastr';
import {
  getOrganizationSitesAPI,
  postOrganizationSiteAPI,
  putOrganizationSiteAPI,
  deleteOrganizationSiteAPI,
} from '../../api';
import { getLocalStorageSelectedSite } from '../../helpers/localStorage/sites';

export const getOrganizationSites = createAsyncThunk(
  'sites/getOrganizationSites',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().sites;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());
    const sites = await getOrganizationSitesAPI(organization.item_id);
    const { selected: currentSite } = getState().sites;
    let selected =
      currentSite?.item_id !== organization.item_id ? {} : currentSite;
    const localStorageSite = find(sites, [
      'relation_id',
      getLocalStorageSelectedSite(organization.item_id),
    ]);
    selected = selected.item_id ? selected : localStorageSite || selected;
    dispatch(hideLoading());

    return { sites, selected };
  }
);

export const postOrganizationSite = createAsyncThunk(
  'sites/postOrganizationSite',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().sites;
      const { item_id: orgId } = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const sites = await postOrganizationSiteAPI(id || orgId, data);
      dispatch(
        addToastr({
          title: 'Site created!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { sites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to create Site',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationSite = createAsyncThunk(
  'sites/putOrganizationSite',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().sites;
      const { item_id: orgId } = getState().organization.default;
      const selectedSiteId = getState().sites.selected?.relation_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const sites = await putOrganizationSiteAPI(id || orgId, data);

      // return currently selected site, if updated
      const selected =
        data.relation_id === selectedSiteId
          ? find(sites, ['relation_id', selectedSiteId])
          : null;

      dispatch(
        addToastr({
          title: 'Site updated!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return selected ? { sites, selected } : { sites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to update Site',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationSite = createAsyncThunk(
  'sites/deleteOrganizationSite',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().sites;
      const { item_id: orgId } = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const sites = await deleteOrganizationSiteAPI(
        id || orgId,
        data.relation_id
      );
      dispatch(
        addToastr({
          title: 'Site deleted!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { sites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete Site',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
