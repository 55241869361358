import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get } from 'lodash';

import { getAdminStatsAPI } from '../../api';

export const getAdminStats = createAsyncThunk(
  'adminStats/getAdminStats',
  async (user, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().adminStats;
      const { user: currentUser } = getState().user;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      if (get(user || currentUser, 'super_user')) {
        const adminStats = await getAdminStatsAPI();
        return { adminStats };
      }
      return { adminStats: {} };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);
