import { colors, chartColors } from './_variables';
import { veregy } from './veregyTheme';
import { createTheme } from '@mui/material/styles';

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const mergeThemes = (customTheme) =>
  createTheme({
    palette: {
      primary: {
        main: customTheme.primary || colors.brand_primary,
      },
      secondary: {
        main: customTheme.secondary || colors.brand_secondary,
      },
    },
    flex: {
      flex: 1,
    },
    fontAwesomeIcon: {
      width: 40,
      height: 40,
    },
    typography: {
      fontFamily: veregy.typography.fontFamily,
      fontSize: 12,
      fontWeightLight: 100,
      fontWeightRegular: 200,
      fontWeightMedium: 300,
    },
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: breakpoints,
    },
    colors: {
      ...veregy.colors,
      ...colors,
      hyperlink: colors.brand_primary,
    },
    drawerWidth: 221,
    drawerWidthCollapse: 62,
    chartColors: chartColors,
    tableEditColumnWidth: {
      single: 58,
      double: 90,
      triple: 122,
      doubleCompact: 78,
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'inherit',
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '& .MuiFormControl-root': {
              flex: 1,
              marginTop: '4px',
            },
            '& .MuiAutocomplete-root': {
              flex: 1,
              marginTop: '4px',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: { minWidth: '400px' },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            '& .MuiList-root .MuiListItemText-root:not(:first-of-type)': {
              marginTop: '1.8rem',
            },
          },
        },
      },
    },
  });
