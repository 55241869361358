import { createSlice, current } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { sites as initialState } from '../initialState';

import { first } from 'lodash';

import {
  getOrganizationSites,
  postOrganizationSite,
  putOrganizationSite,
  deleteOrganizationSite,
} from './_sites';

import { setLocalStorageSelectedSite } from '../../helpers/localStorage/sites';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    clearSites: () => ({ ...initialState }),
    setSelectedSite: (state, { payload }) => {
      const { item_id, relation_id } = payload;
      const orgId = item_id || first(current(state).sites)?.item_id;
      const siteId = relation_id || null;
      setLocalStorageSelectedSite(orgId, siteId);
      return {
        ...state,
        selected: payload,
      };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationSites,
      postOrganizationSite,
      putOrganizationSite,
      deleteOrganizationSite,
    ]);
  },
});

// Extract each action creator by name
const { clearSites, setSelectedSite } = actions;

// Export the reducer, either as a default or named export
export {
  clearSites,
  setSelectedSite,
  getOrganizationSites,
  postOrganizationSite,
  putOrganizationSite,
  deleteOrganizationSite,
};
export default reducer;
