import { useSelector } from 'react-redux';
import fastCompare from 'react-fast-compare';

export function useWidgetData(dashboardId, widgetId) {
  const widgetData = useSelector(
    (state) => state.dashboards.data[dashboardId]?.[widgetId] ?? null,
    fastCompare
  );

  return widgetData;
}

export default useWidgetData;
