import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { schedules as initialState } from '../initialState';

import {
  postOrganizationSchedule,
  getOrganizationSchedules,
  forceOrganizationSchedulePoll,
  putOrganizationSchedule,
  deleteOrganizationSchedule,
  batchDeleteOrganizationSchedules,
  batchDuplicateOrganizationSchedules,
} from './_schedules';

import {
  getOrganizationScheduleEvents,
  getOrganizationScheduleDevices,
  getOrganizationScheduleResources,
} from './_relations';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    clearSchedules: () => ({ ...initialState }),
    setSchedules(state, action) {
      state.schedules = action.payload;
    },
    setSelectedSchedule(state, action) {
      state.selected = action.payload || initialState.selected;
    },
    setExceptionEvents(state, action) {
      state.exceptionEvents = action.payload;
    },
    setScheduleDevices(state, action) {
      state.scheduleDevices = action.payload;
    },
    setScheduleResources(state, action) {
      state.scheduleResources = action.payload;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      postOrganizationSchedule,
      getOrganizationSchedules,
      forceOrganizationSchedulePoll,
      putOrganizationSchedule,
      deleteOrganizationSchedule,
      batchDeleteOrganizationSchedules,
      batchDuplicateOrganizationSchedules,
      getOrganizationScheduleEvents,
      getOrganizationScheduleDevices,
      getOrganizationScheduleResources,
    ]);
  },
});

// Export the reducer, either as a default or named export
const {
  clearSchedules,
  setSchedules,
  setSelectedSchedule,
  setExceptionEvents,
  setScheduleDevices,
  setScheduleResources,
} = actions;
export {
  clearSchedules,
  setSchedules,
  setSelectedSchedule,
  setExceptionEvents,
  setScheduleDevices,
  setScheduleResources,
  getOrganizationSchedules,
  forceOrganizationSchedulePoll,
  postOrganizationSchedule,
  putOrganizationSchedule,
  deleteOrganizationSchedule,
  batchDeleteOrganizationSchedules,
  batchDuplicateOrganizationSchedules,
  getOrganizationScheduleEvents,
  getOrganizationScheduleDevices,
  getOrganizationScheduleResources,
};
export default reducer;
