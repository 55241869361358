import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  getOrganizationInviteCodesAPI,
  postOrganizationInviteCodeAPI,
  deleteOrganizationInviteCodeAPI,
  putOrganizationInviteCodeAPI,
  verifyOrganizationInviteCodeAPI,
} from '../../api';
import { addToastr, types } from '../toastr';

export const getOrganizationInviteCodes = createAsyncThunk(
  'organization/getOrganizationInviteCodes',
  async (org_id, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().organization;
      const { item_id: orgId } = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const invites = await getOrganizationInviteCodesAPI(
        org_id ? org_id : orgId
      );
      return { invites };
    } catch (err) {
      console.error(err);
    }
  }
);

export const postOrganizationInviteCode = createAsyncThunk(
  'organization/postOrganizationInviteCode',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      dispatch(showLoading());
      const invites = await postOrganizationInviteCodeAPI(id, data);
      dispatch(
        addToastr({
          title: 'Invite code created!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { invites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to create invite code',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationInviteCode = createAsyncThunk(
  'organization/deleteOrganizationInviteCode',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      dispatch(showLoading());
      const invites = await deleteOrganizationInviteCodeAPI(
        id,
        data.relation_id
      );
      dispatch(
        addToastr({
          title: 'Invite code deleted!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { invites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete invite code',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationInviteCode = createAsyncThunk(
  'organization/putOrganizationInviteCode',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      dispatch(showLoading());
      const invites = await putOrganizationInviteCodeAPI(id, data);
      dispatch(
        addToastr({
          title: 'Invite code updated!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { invites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to update invite code',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const verifyOrganizationInviteCode = createAsyncThunk(
  'organization/verifyOrganizationInviteCode',
  async (code, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, invites } = getState().organization;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const org = await verifyOrganizationInviteCodeAPI(code);

      dispatch(
        addToastr({
          title: 'Invite code verified!',
          type: types.success,
          message: `You've been added to ${get(org, 'name')}`,
        })
      );

      return { invites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to verify invite code',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
