import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { credentials as initialState } from '../initialState';

import {
  postOrganizationCredential,
  getOrganizationCredentials,
  putOrganizationCredential,
  deleteOrganizationCredential,
  pollOrganizationCredentials,
  getOrganizationCredentialStatus,
  implementedGetStatusServices,
} from './_credentials';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    clearCredentials: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      postOrganizationCredential,
      getOrganizationCredentials,
      putOrganizationCredential,
      deleteOrganizationCredential,
      pollOrganizationCredentials,
      getOrganizationCredentialStatus,
    ]);
  },
});

const { clearCredentials } = actions;

// Export the reducer, either as a default or named export
export {
  clearCredentials,
  getOrganizationCredentials,
  postOrganizationCredential,
  putOrganizationCredential,
  deleteOrganizationCredential,
  pollOrganizationCredentials,
  getOrganizationCredentialStatus,
  implementedGetStatusServices,
};
export default reducer;
