const ROUTES = {
  UNAUTH: {
    LANDING: '/',
    LOGIN: '/account/login',
    LOGOUT: '/account/logout',
    FORGOT_PASSWORD: '/account/forgot-password',
    NEW_PASSWORD: '/account/new-password',
    TERMS: '/terms',
    PRIVACY: '/privacy',
  },
  AUTH: {
    WORKSPACE: '/workspace',
    EVENTS: '/events',
    CONNECTIONS: '/connections',
    TRENDS: '/trends',
    NOTIFICATIONS: '/notifications',
    FILES: '/files',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    ORGANIZATION_ADMIN: '/organization-admin',
    ADMIN: '/admin',
  },
};

export default ROUTES;
