import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { trends as initialState } from '../initialState';

import {
  getOrganizationTrends,
  getOrganizationTrendData,
  deleteOrganizationTrend,
  batchDeleteOrganizationTrends,
} from './_trends';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'trends',
  initialState,
  reducers: {
    clearTrends: () => ({ ...initialState }),
    setTrends(state, action) {
      state.trends = action.payload;
    },
    clearTrendsData: (state) => {
      return {
        ...state,
        data: [],
      };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationTrends,
      getOrganizationTrendData,
      deleteOrganizationTrend,
      batchDeleteOrganizationTrends,
    ]);
  },
});

// Export the reducer, either as a default or named export
const { clearTrends, setTrends, clearTrendsData } = actions;
export {
  clearTrends,
  setTrends,
  clearTrendsData,
  getOrganizationTrends,
  getOrganizationTrendData,
  deleteOrganizationTrend,
  batchDeleteOrganizationTrends,
};
export default reducer;
