import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { uploads as initialState } from '../initialState';

import { generateLogoPutUrl, deleteLogoObject } from './_logos';
import {
  getOrganizationFiles,
  postUploadFile,
  putUploadFile,
  generateGetUrl,
  deleteUploadObject,
  batchDeleteUploadObjects,
  batchPutUploadFiles,
  batchPostUploadFiles,
} from './_files';
import {
  postOrganizationFolder,
  putOrganizationFolder,
  deleteOrganizationFolder,
} from './_folders';
import {
  batchMoveOrganizationFilesAndFolders,
  batchDownloadFilesAndFolders,
  batchDeleteFilesAndFolders,
} from './_bulk';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    clearUploads: () => ({ ...initialState }),
    setUploadProgress: (state, { payload }) => ({
      ...state,
      progress: {
        ...state.progress,
        ...payload,
      },
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationFiles,
      postUploadFile,
      putUploadFile,
      generateGetUrl,
      deleteUploadObject,
      generateLogoPutUrl,
      deleteLogoObject,
      batchDeleteUploadObjects,
      batchPutUploadFiles,
      batchPostUploadFiles,
      postOrganizationFolder,
      putOrganizationFolder,
      deleteOrganizationFolder,
      batchMoveOrganizationFilesAndFolders,
      batchDownloadFilesAndFolders,
      batchDeleteFilesAndFolders,
    ]);
  },
});

const { clearUploads, setUploadProgress } = actions;

// Export the reducer, either as a default or named export
export {
  clearUploads,
  setUploadProgress,
  getOrganizationFiles,
  postUploadFile,
  putUploadFile,
  generateGetUrl,
  deleteUploadObject,
  generateLogoPutUrl,
  deleteLogoObject,
  batchDeleteUploadObjects,
  batchPutUploadFiles,
  batchPostUploadFiles,
  postOrganizationFolder,
  putOrganizationFolder,
  deleteOrganizationFolder,
  batchMoveOrganizationFilesAndFolders,
  batchDownloadFilesAndFolders,
  batchDeleteFilesAndFolders,
};
export default reducer;
