import { useSelector } from 'react-redux';
import { get } from 'lodash';
import fastCompare from 'react-fast-compare';

export function useWidgetGroupValue(widgetGroupId, attrKey) {
  const dashboardId = useSelector(
    (state) => state.dashboards.selected?.relation_id
  );
  const widgetGroupValue = useSelector(
    (state) =>
      widgetGroupId
        ? get(
            state.dashboards.dashboards[dashboardId]?.widget_groups.find(
              (w) => w.relation_id === widgetGroupId
            ),
            attrKey
          )
        : undefined,
    fastCompare
  );

  return widgetGroupValue;
}

export default useWidgetGroupValue;
