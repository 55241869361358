import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, isEqual } from 'lodash';

import { addToastr, types } from '../toastr';
import {
  batchDeleteOrganizationTicketsAPI,
  pollOrganizationWorkOrdersAPI,
} from '../../api';

export const batchDeleteOrganizationTickets = createAsyncThunk(
  'organization/batchDeleteOrganizationTickets',
  async (_, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().organization;
      const initialWorkOrders = getState().organization.work_orders;
      const initialAlarms = getState().organization.alarms;

      const selected = getState().bulkEdit.selected;

      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const payload = selected.map((row) => {
        const { alarm, work_order, work_order_count } = row;
        return {
          alarm: {
            item_id: alarm.item_id,
            relation_id: alarm.relation_id,
          },
          work_order: work_order && {
            item_id: work_order.item_id,
            relation_id: work_order.relation_id,
          },
          work_order_count,
        };
      });

      dispatch(showLoading());
      const { alarms: deletedAlarms, work_orders: deletedWorkOrders } =
        await batchDeleteOrganizationTicketsAPI(organization.item_id, payload);
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Deleted ${selected.length} ${
            selected.length > 1 ? 'Tickets' : 'Ticket'
          }.`,
        })
      );

      const deletedAlarmIds = deletedAlarms.map(
        (alarm) => `${alarm.item_id}|${alarm.relation_id}`
      );
      const alarms = initialAlarms.filter(
        (alarm) =>
          !deletedAlarmIds.includes(`${alarm.item_id}|${alarm.relation_id}`)
      );

      const deletedWorkOrderIds = deletedWorkOrders.map(
        (wo) => `${wo.item_id}|${wo.relation_id}`
      );
      const work_orders = initialWorkOrders.filter(
        (wo) => !deletedWorkOrderIds.includes(`${wo.item_id}|${wo.relation_id}`)
      );

      return { alarms, work_orders };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete Tickets',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const pollOrganizationWorkOrders = createAsyncThunk(
  'organization/pollOrganizationWorkOrders',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().organization;
    const organization = getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());
    await pollOrganizationWorkOrdersAPI(organization.item_id);
    dispatch(hideLoading());

    return {};
  }
);
