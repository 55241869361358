import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, isEqual } from 'lodash';

import {
  generatePutUrlAPI,
  generateGetUrlAPI,
  deleteUploadObjectAPI,
  getOrganizationDetailsAPI,
  putUploadFileAPI,
  batchDeleteUploadObjectsAPI,
  batchPutUploadFilesAPI,
} from '../../api';
import { addToastr, types } from '../toastr';
import { setUploadProgress } from '.';

export const getOrganizationFiles = createAsyncThunk(
  'uploads/getOrganizationFiles',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());

    const detailTypes = ['files', 'folders'];
    const detailResponses = await Promise.allSettled(
      detailTypes.map((detail) =>
        getOrganizationDetailsAPI(organization.item_id, detail)
      )
    );
    const detailArray = detailResponses.map((res) => res.value);
    const details = Object.assign({}, ...detailArray);

    // const { files } = await getOrganizationDetailsAPI(
    //   organization.item_id,
    //   'files'
    // );
    dispatch(hideLoading());

    return { ...details };
    // return { files };
  }
);

export const postUploadFile = createAsyncThunk(
  'uploads/postUploadFile',
  async ({ orgId, fileItem, file }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, files } = getState().uploads;

    const sites = getState().sites.sites;
    const orgName = getState().organization.default.name;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const { signed_url, added } = await generatePutUrlAPI(
        orgId,
        fileItem,
        file.type
      );

      try {
        const config = {
          headers: {
            'Content-Type': file.type,
          },
        };
        await axios.put(signed_url, file, config);

        const addedTo = fileItem.parent.startsWith('org:')
          ? orgName
          : sites.find((site) => site.relation_id === fileItem.parent).name;

        dispatch(
          addToastr({
            title: 'File Uploaded!',
            type: types.success,
            message: `Added ${fileItem.name} to ${addedTo}.`,
          })
        );

        return { files: [...files, added] };
      } catch (err) {
        console.error(err);
        dispatch(
          addToastr({
            title: 'Failed to upload file',
            type: types.error,
            message: err.message ? [err.message] : [err],
          })
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to upload file',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchPostUploadFiles = createAsyncThunk(
  'uploads/batchPostUploadFiles',
  async ({ orgId, properties, files }, { dispatch, getState, requestId }) => {
    const {
      currentRequestId,
      loading,
      files: initialFiles,
    } = getState().uploads;

    const sites = getState().sites.sites;
    const orgName = getState().organization.default.name;

    // if (loading !== true || requestId !== currentRequestId) {
    //   return;
    // }

    try {
      dispatch(showLoading());

      const putUrlResponses = await Promise.allSettled(
        files.map((file) =>
          generatePutUrlAPI(
            orgId,
            { ...properties, name: file.name, size: file.size },
            file.type
          ).then((res) => {
            const { added, signed_url } = res;
            const config = {
              onUploadProgress: (progressEvent) => {
                const progress =
                  (progressEvent.loaded / progressEvent.total) * 100;
                dispatch(setUploadProgress({ [added.relation_id]: progress }));
              },
              headers: {
                'Content-Type': file.type,
              },
            };
            axios
              .put(signed_url, file, config)
              .then(
                ({ status }) =>
                  status === 200 &&
                  putUploadFileAPI(orgId, added.relation_id, {
                    active: true,
                  })
              )
              .catch((err) => console.error(err));
            const progress = file.size > 0 ? 0 : 100; // prevent bug if file size is 0
            dispatch(setUploadProgress({ [added.relation_id]: progress }));
            return added;
          })
        )
      );
      const newFiles = putUrlResponses.map((res) => res.value);

      try {
        const addedTo = properties.parent.startsWith('org:')
          ? orgName
          : sites.find((site) => site.relation_id === properties.parent).name;

        dispatch(
          addToastr({
            title: 'Files Uploaded!',
            type: types.success,
            message: `Added ${files.length} files to ${addedTo}.`,
          })
        );

        return { files: [...initialFiles, ...newFiles] };
      } catch (err) {
        console.error(err);
        dispatch(
          addToastr({
            title: 'Failed to upload file',
            type: types.error,
            message: err.message ? [err.message] : [err],
          })
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to upload file',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const generateGetUrl = createAsyncThunk(
  'uploads/generateGetUrl',
  async (
    { orgId, fileId, contentDisposition = 'attachment' },
    { dispatch }
  ) => {
    try {
      dispatch(showLoading());

      const getUrl = await generateGetUrlAPI(orgId, fileId, contentDisposition);

      if (contentDisposition === 'inline') {
        window.open(getUrl, '_blank');
      } else if (contentDisposition === 'attachment') {
        const downloadLink = document.createElement('a');
        downloadLink.href = getUrl;
        downloadLink.style.display = 'none';
        const filename = getUrl.match('filename%3D%22(.*)%22')[1];
        downloadLink.setAttribute('download', filename ?? '');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteUploadObject = createAsyncThunk(
  'uploads/deleteUploadObject',
  async ({ orgId, fileId }, { dispatch }) => {
    try {
      dispatch(showLoading());
      const { files } = await deleteUploadObjectAPI(orgId, fileId);

      dispatch(
        addToastr({
          title: 'File Deleted!',
          type: types.success,
        })
      );

      return { files };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete file',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putUploadFile = createAsyncThunk(
  'uploads/putUploadFile',
  async ({ orgId, fileItem }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const { files } = await putUploadFileAPI(
        orgId,
        fileItem.relation_id,
        fileItem
      );

      dispatch(
        addToastr({
          title: 'File Updated!',
          type: types.success,
        })
      );

      return { files };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to update file',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchPutUploadFiles = createAsyncThunk(
  'uploads/batchPutUploadFiles',
  async ({ file_updates }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;

    const orgId = getState().organization.default.item_id;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const { files } = await batchPutUploadFilesAPI(orgId, file_updates);

      dispatch(
        addToastr({
          title: 'Files Updated!',
          type: types.success,
        })
      );

      return { files };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to update files',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchDeleteUploadObjects = createAsyncThunk(
  'uploads/batchDeleteUploadObjects',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;

    const selected = getState().bulkEdit.selected;
    const orgId = getState().organization.default.item_id;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const fileIds = selected.map((file) => file.relation_id);

    try {
      dispatch(showLoading());
      const { files } = await batchDeleteUploadObjectsAPI(orgId, fileIds);

      dispatch(
        addToastr({
          title: 'Files Deleted!',
          type: types.success,
        })
      );

      return { files };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete files',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
