import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

/** MIDDLEWARE **/
import thunkMiddleware from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';

/** REDUCERS **/
import { reducer as toastr } from 'react-redux-toastr';
import { loadingBarReducer } from 'react-redux-loading-bar';
import appSettings from './appSettings';
import admin from './admin';
import auth from './auth';
import apps from './apps';
import cmms from './cmms';
import edge from './edge';
import toast from './toastr';
import user from './user';
import organization from './organization';
import schedules from './schedules';
import credentials from './credentials';
import sites from './sites';
import uploads from './uploads';
import adminStats from './adminStats';
import orgStats from './orgStats';
import devices from './devices';
import resources from './resources';
import serviceSites from './serviceSites';
import trends from './trends';
import trendCharts from './trendCharts';
import dashboards from './dashboards';
import bulkEdit from './bulkEdit';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    appSettings,
    admin,
    auth,
    apps,
    cmms,
    edge,
    loadingBar: loadingBarReducer,
    router: routerReducer,
    schedules,
    credentials,
    sites,
    toast,
    toastr,
    user,
    organization,
    uploads,
    adminStats,
    orgStats,
    devices,
    resources,
    serviceSites,
    trends,
    trendCharts,
    dashboards,
    bulkEdit,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware, thunkMiddleware, loadingBarMiddleware()),
});

export const history = createReduxHistory(store);
