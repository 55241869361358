import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { devices as initialState } from '../initialState';

import {
  getOrganizationDevices,
  postOrganizationDevice,
  putOrganizationDevice,
  deleteOrganizationDevice,
  batchDeleteOrganizationDevices,
  batchPutOrganizationDevices,
} from './_devices';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    clearDevices: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationDevices,
      postOrganizationDevice,
      putOrganizationDevice,
      deleteOrganizationDevice,
      batchDeleteOrganizationDevices,
      batchPutOrganizationDevices,
    ]);
  },
});

// Export the reducer, either as a default or named export
const { clearDevices } = actions;
export {
  clearDevices,
  getOrganizationDevices,
  postOrganizationDevice,
  putOrganizationDevice,
  deleteOrganizationDevice,
  batchDeleteOrganizationDevices,
  batchPutOrganizationDevices,
};
export default reducer;
