import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get } from 'lodash';

import { addToastr, types } from '../toastr';
import {
  postCredentialWorkOrderTemplateAPI,
  putCredentialWorkOrderTemplateAPI,
  deleteCredentialWorkOrderTemplateAPI,
} from '../../api';

const postCredentialWorkOrderTemplate = createAsyncThunk(
  'organization/postCredentialWorkOrderTemplate',
  async ({ credential, data }, { dispatch, getState, requestId }) => {
    try {
      // check cred or org loading state?
      // const { currentRequestId, loading } = getState().credentials;

      // if (loading !== true || requestId !== currentRequestId) {
      //   return;
      // }

      dispatch(showLoading());
      const templates = await postCredentialWorkOrderTemplateAPI(
        credential.item_id,
        credential.relation_id,
        data
      );

      dispatch(
        addToastr({
          title: 'Work Orders Configured!',
          type: types.success,
          message: `Added a work order template for ${credential.name}.`,
        })
      );

      return { templates };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to configure Work Orders',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putCredentialWorkOrderTemplate = createAsyncThunk(
  'organization/putCredentialWorkOrderTemplate',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const organization = getState().organization.default;

      dispatch(showLoading());
      const templates = await putCredentialWorkOrderTemplateAPI(
        organization.item_id,
        data
      );
      dispatch(
        addToastr({
          title: 'Work Order Configuration Updated!',
          type: types.success,
        })
      );

      return { templates };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Work Order Configuration',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteCredentialWorkOrderTemplate = createAsyncThunk(
  'organization/deleteCredentialWorkOrderTemplate',
  async ({ orgId, credId, templateId }, { dispatch }) => {
    try {
      dispatch(showLoading());

      const templates = await deleteCredentialWorkOrderTemplateAPI(
        orgId,
        credId,
        templateId
      );
      dispatch(
        addToastr({
          title: 'Work Order Configuration Deleted!',
          type: types.success,
        })
      );

      return { templates };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to delete Work Order Configuration',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  postCredentialWorkOrderTemplate,
  putCredentialWorkOrderTemplate,
  deleteCredentialWorkOrderTemplate,
};
