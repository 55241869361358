import React, { useState } from 'react';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';

import { useMountEffect } from '@react-hookz/web';
import { v4 as uuidv4 } from 'uuid';
import ClipboardJS from 'clipboard';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HistoryRouter } from 'redux-first-history/rr6';

import ReduxToastr from 'react-redux-toastr';
import LoadingBar from 'react-redux-loading-bar';
import { SkeletonTheme } from 'react-loading-skeleton';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { mergeThemes } from './styles/muiTheme';

import './styles/App.css';
import './styles/index.css';
import './styles/google-autocomplete.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles/main.scss';
import './styles/react-grid-layout.scss';

import { history, store } from './store/createStore';
import { configure } from './store/auth';

import { useCustomTheme } from './helpers/theme';
import { useOrganizationRedirect } from './helpers/navigation';

import icons from './assets/icons';

import SplashPage from './components/SplashPage';
import BulkEditMenu from './components/BulkEditMenu';
import AppRoutes from './routes';

// Any button used to copy text to the clipboard should use the className="copy-btn" (or include it in classnames list)
new ClipboardJS('.copy-btn');

// INITIALIZE
icons();

const LoadingBarStyled = styled(LoadingBar)(({ theme }) => ({
  backgroundColor: theme.colors.green,
  height: '3px',
  position: 'absolute',
  zIndex: 99999,
}));

function App() {
  const dispatch = useDispatch();
  const { splashPage } = useSelector((state) => state.appSettings);

  useOrganizationRedirect(history);

  const [authReady, setAuthReady] = useState(false);

  useMountEffect(() => {
    const setupAuth = async () => {
      await dispatch(configure());
      setAuthReady(true);
    };

    setupAuth();
  });

  return (
    <HistoryRouter history={history}>
      <LoadingBarStyled />
      <HelmetProvider>
        <Helmet key={uuidv4()}>
          <title>Orchestrate Hub</title>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
        </Helmet>
      </HelmetProvider>
      <BulkEditMenu />
      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates={false}
        getState={(state) => state.toastr}
        position='bottom-right'
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        progressBar
        closeOnToastrClick
      />
      <SplashPage />
      {authReady && (
        <div className='App' style={splashPage ? { display: 'none' } : {}}>
          <AppRoutes />
        </div>
      )}
    </HistoryRouter>
  );
}

function CustomThemeProvider({ children }) {
  const customTheme = useCustomTheme();
  const muiTheme = mergeThemes(customTheme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <SkeletonTheme baseColor={muiTheme.colors.lightGrey}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {children}
          </LocalizationProvider>
        </SkeletonTheme>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function AppProvider({ children }) {
  return (
    <ReduxProvider store={store}>
      <CustomThemeProvider>
        <App>{children}</App>
      </CustomThemeProvider>
    </ReduxProvider>
  );
}

export default AppProvider;
