import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, find } from 'lodash';

import { addToastr, types } from '../toastr';

import {
  getOrganizationsAPI,
  getOrganizationDetailsAPI,
  putOrganizationAPI,
  postOrganizationAPI,
  deleteOrganizationAPI,
} from '../../api';

export const getOrganizations = createAsyncThunk(
  'organization/getOrganizations',
  async (_, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().organization;
      const defaultOrgId = getState().user.user.default_org;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const organizations = await getOrganizationsAPI();
      const defaultOrg = find(organizations, { item_id: defaultOrgId });

      return { all: organizations, default: defaultOrg || {} };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const getOrganizationDetails = createAsyncThunk(
  'organization/getOrganizationDetails',
  async (
    {
      id,
      itemTypes = [
        'equipment',
        'alarms',
        'work_orders',
        'templates',
        'invites',
        'users',
      ],
    },
    { dispatch, getState, requestId }
  ) => {
    try {
      const { currentRequestId, loading } = getState().organization;
      const organization = getState().organization.default;
      const orgId = id ?? organization.item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const paginate = ['equipment'];

      const detailResponses = await Promise.allSettled(
        itemTypes.map((itemType) =>
          getOrganizationDetailsAPI(
            orgId,
            itemType,
            paginate.includes(itemType)
          )
        )
      );
      const detailArray = detailResponses.map((res) => res.value);
      const details = Object.assign({}, ...detailArray);

      const errors = detailResponses.filter(
        ({ status }) => status === 'rejected'
      );

      // if (errors.length) {
      //   dispatch(
      //     addToastr({
      //       title: 'Failed to retrieve Organization details',
      //       type: types.error,
      //       // message: get(data, 'name'),
      //     })
      //   );
      // }

      dispatch(hideLoading());

      return { ...details };
    } catch (err) {
      dispatch(hideLoading());
      console.error(err);
    }
  }
);

export const putOrganization = createAsyncThunk(
  'organization/putOrganization',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        default: currentOrg,
      } = getState().organization;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const organizations = await putOrganizationAPI(id, data);
      const defaultOrg =
        currentOrg.item_id === id
          ? { default: find(organizations, { item_id: id }) }
          : {};

      dispatch(
        addToastr({
          title: 'Organization Updated!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { all: organizations, ...defaultOrg };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Organization',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const postOrganization = createAsyncThunk(
  'organization/postOrganization',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().organization;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const organizations = await postOrganizationAPI(data);

      dispatch(
        addToastr({
          title: 'Organization Added!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { all: organizations };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add Organization',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  'organization/deleteOrganization',
  async ({ id }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().organization;
      const previousOrgs = getState().organization.all;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const organizations = await deleteOrganizationAPI(id);

      dispatch(
        addToastr({
          title: 'Organization Deleted!',
          type: types.success,
          message: find(previousOrgs, { item_id: id }).name,
        })
      );

      return { all: organizations };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to delete Organization',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);
