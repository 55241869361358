import { createSlice, current } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { organization as initialState } from '../initialState';

import {
  getOrganizations,
  getOrganizationDetails,
  putOrganization,
  postOrganization,
  deleteOrganization,
} from './_organizations';

import {
  getOrganizationUsers,
  postOrganizationUser,
  putOrganizationUser,
  deleteOrganizationUser,
} from './_users';

import {
  getOrganizationInviteCodes,
  postOrganizationInviteCode,
  deleteOrganizationInviteCode,
  putOrganizationInviteCode,
  verifyOrganizationInviteCode,
} from './_inviteCodes';

import {
  postCredentialWorkOrderTemplate,
  putCredentialWorkOrderTemplate,
  deleteCredentialWorkOrderTemplate,
} from './_templates';

import {
  batchDeleteOrganizationTickets,
  pollOrganizationWorkOrders,
} from './_tickets';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    clearOrganizationDetails: (state, { payload }) => ({
      ...initialState,
      all: state.all,
      default: payload.default || initialState.default,
    }),
    clearOrganizations: () => ({ ...initialState }),
    setOrganizations: (state, { payload }) => ({
      ...state,
      all: payload.all || state.all,
      default: payload.default || state.default,
    }),
    setDefaultOrganization: (state, { payload }) => ({
      ...state,
      default: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizations,
      getOrganizationDetails,
      putOrganization,
      postOrganization,
      deleteOrganization,
      getOrganizationUsers,
      postOrganizationUser,
      putOrganizationUser,
      deleteOrganizationUser,
      getOrganizationInviteCodes,
      postOrganizationInviteCode,
      deleteOrganizationInviteCode,
      putOrganizationInviteCode,
      verifyOrganizationInviteCode,
      postCredentialWorkOrderTemplate,
      putCredentialWorkOrderTemplate,
      deleteCredentialWorkOrderTemplate,
      batchDeleteOrganizationTickets,
      pollOrganizationWorkOrders,
    ]);
  },
});

// Extract each action creator by name
const {
  clearOrganizationDetails,
  clearOrganizations,
  setOrganizations,
  setDefaultOrganization,
} = actions;

// Export the reducer, either as a default or named export
export {
  // setters
  clearOrganizationDetails,
  clearOrganizations,
  setOrganizations,
  setDefaultOrganization,
  // organizations
  getOrganizations,
  getOrganizationDetails,
  putOrganization,
  postOrganization,
  deleteOrganization,
  // org-users
  getOrganizationUsers,
  postOrganizationUser,
  putOrganizationUser,
  deleteOrganizationUser,
  // invite-codes
  getOrganizationInviteCodes,
  postOrganizationInviteCode,
  deleteOrganizationInviteCode,
  putOrganizationInviteCode,
  verifyOrganizationInviteCode,
  // templates
  postCredentialWorkOrderTemplate,
  putCredentialWorkOrderTemplate,
  deleteCredentialWorkOrderTemplate,
  // tickets
  batchDeleteOrganizationTickets,
  pollOrganizationWorkOrders,
};
export default reducer;
