import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import {
  init,
  replayIntegration,
  captureConsoleIntegration,
} from '@sentry/react';

import _package from '../../package.json';
import ROUTES from '../constants/routes';

const Authentication = loadable(() => import('./Authentication'));
const Terms = loadable(() => import('./TermsAndPrivacy'), {
  resolveComponent: (components) => components.Terms,
});
const Privacy = loadable(() => import('./TermsAndPrivacy'), {
  resolveComponent: (components) => components.Privacy,
});

const Navigation = loadable(() => import('./MainNavigation'));

const Workspace = loadable(() => import('./Workspace'));
const Events = loadable(() => import('./Events'));
const Connections = loadable(() => import('./Connections'));
const Trends = loadable(() => import('./Trends'));
const Notifications = loadable(() => import('./Notifications'));
const Files = loadable(() => import('./Files'));
const Organization = loadable(() => import('./Organization'));
const Profile = loadable(() => import('./Profile'));
const OrganizationAdmin = loadable(() => import('./OrganizationAdmin'));
const Admin = loadable(() => import('./Admin'));

if (['staging', 'prod', 'production'].includes(process.env.REACT_APP_ENV)) {
  init({
    dsn: 'https://5ae4638cef73f5c2ebfab054d6dd089a@o240785.ingest.us.sentry.io/4507272399683584',
    environment: process.env.REACT_APP_ENV,
    release: _package.version,
    integrations: [
      replayIntegration({ blockAllMedia: false }),
      captureConsoleIntegration({ levels: ['info', 'warn', 'error'] }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function AppRoutes() {
  const authenticated = useSelector((state) => state.auth.authenticated);

  return (
    <Routes>
      {/* Index redirect */}
      <Route
        path='/'
        element={
          <Navigate
            to={authenticated ? ROUTES.AUTH.WORKSPACE : ROUTES.UNAUTH.LOGIN}
          />
        }
      />

      <Route path={ROUTES.UNAUTH.TERMS} element={<Terms />} />
      <Route path={ROUTES.UNAUTH.PRIVACY} element={<Privacy />} />

      {/* Unauthenticated routes */}
      <Route path='/account/:method' element={<Authentication />} />
      <Route path='/login' element={<Navigate to={ROUTES.UNAUTH.LOGIN} />} />
      <Route path='/logout' element={<Navigate to={ROUTES.UNAUTH.LOGOUT} />} />

      {/* Authenticated routes */}
      {authenticated && (
        <Route path='/' element={<Navigation />}>
          <Route index element={<Navigate to={ROUTES.AUTH.WORKSPACE} />} />

          <Route path={ROUTES.AUTH.WORKSPACE} element={<Workspace />} />
          <Route path={ROUTES.AUTH.EVENTS} element={<Events />} />
          <Route path={ROUTES.AUTH.CONNECTIONS} element={<Connections />} />
          <Route path={ROUTES.AUTH.TRENDS} element={<Trends />} />
          <Route path={ROUTES.AUTH.NOTIFICATIONS} element={<Notifications />} />
          <Route path={ROUTES.AUTH.FILES} element={<Files />} />
          <Route path={ROUTES.AUTH.SETTINGS} element={<Organization />} />
          <Route
            path={ROUTES.AUTH.ORGANIZATION_ADMIN}
            element={<OrganizationAdmin />}
          />
          <Route path={ROUTES.AUTH.ADMIN} element={<Admin />} />
          <Route path={ROUTES.AUTH.PROFILE} element={<Profile />} />
        </Route>
      )}

      <Route path={'*'} element={<Navigate to={ROUTES.UNAUTH.LANDING} />} />
    </Routes>
  );
}

export default AppRoutes;
