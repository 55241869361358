import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { getOrganizationDashboardWidgetDataAPI } from '../../api';
import { generateRanges } from '../../helpers/dates/charts';
import { setDashboardData, setDashboardWidgetData } from '.';

export const getOrganizationDashboardData = createAsyncThunk(
  'dashboards/getOrganizationDashboardData',
  async ({ dashboardId }, { dispatch, getState }) => {
    const dashboard = getState().dashboards.dashboards[dashboardId];
    const trends = getState().trends.trends;

    if (!dashboard?.widgets?.length) {
      return;
    }

    dispatch(showLoading());
    try {
      // range timestamps are sent in UTC and converted on backend
      const ranges = generateRanges(false, 0);

      const widgetResponses = await Promise.allSettled(
        dashboard.widgets.map((widget) => {
          // TODO: refactor to include full keys in payload
          const trendKeys = widget.trends.map((id) => {
            const trend = trends.find((trend) => trend.item_id === id);
            const { item_id, relation_id } = trend;
            return { item_id, relation_id };
          });
          const range = ranges.find((r) => r.value === widget.date_range);
          const { start, end } = range;
          return getOrganizationDashboardWidgetDataAPI(
            dashboard.item_id,
            dashboard.relation_id,
            widget.relation_id,
            trendKeys,
            start,
            end,
            widget.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            widget.number_options?.number_function
          );
        })
      );
      const widgetDataMap = widgetResponses.reduce((acc, res) => {
        const [widgetId, widgetData] = Object.entries(res.value)[0];
        acc[widgetId] = widgetData;
        return acc;
      }, {});

      dispatch(setDashboardData({ dashboardId, data: widgetDataMap }));
    } catch (error) {
      console.error(error, dashboardId);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const getDashboardWidgetData = createAsyncThunk(
  'dashboards/getDashboardWidgetData',
  async ({ dashboardId, widget }, { dispatch, getState }) => {
    const orgId = getState().organization.default.item_id;
    const trends = getState().trends.trends;

    dispatch(showLoading());
    try {
      // range timestamps are sent in UTC and converted on backend
      const ranges = generateRanges(false, 0);

      const trendKeys = widget.trends.map((id) => {
        const trend = trends.find((trend) => trend.item_id === id);
        const { item_id, relation_id } = trend;
        return { item_id, relation_id };
      });
      const range = ranges.find((r) => r.value === widget.date_range);
      const { start, end } = range;
      const res = await getOrganizationDashboardWidgetDataAPI(
        orgId,
        dashboardId,
        widget.relation_id,
        trendKeys,
        start,
        end,
        widget.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        widget.number_options?.number_function
      );
      const [widgetId, widgetData] = Object.entries(res)[0];

      dispatch(
        setDashboardWidgetData({
          dashboardId,
          widgetId,
          data: widgetData,
        })
      );
    } catch (error) {
      console.error(error, dashboardId, widget.relation_id);
    } finally {
      dispatch(hideLoading());
    }
  }
);
