import {
  BASE_NAME,
  getLocalStorageObjectValue,
  setLocalStorageObjectValue,
} from './util';

const OBJECT_NAME = Object.freeze(`${BASE_NAME}/sites`);

/*
"orchestratehub/sites": {
    "{orgId}": "{siteId}",
    ...
}
*/

export const getLocalStorageSelectedSite = (orgId) =>
  getLocalStorageObjectValue(OBJECT_NAME, orgId);

export const setLocalStorageSelectedSite = (orgId, siteId) =>
  setLocalStorageObjectValue(OBJECT_NAME, orgId, siteId);
