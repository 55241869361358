import { useSelector } from 'react-redux';
import fastCompare from 'react-fast-compare';
import { get } from 'lodash';

export function useWidgetValue(widgetId, attrKey) {
  const dashboardId = useSelector(
    (state) => state.dashboards.selected?.relation_id
  );
  const widgetValue = useSelector(
    (state) =>
      widgetId
        ? get(
            state.dashboards.dashboards[dashboardId]?.widgets.find(
              (w) => w.relation_id === widgetId
            ),
            attrKey
          )
        : undefined,
    fastCompare
  );

  return widgetValue;
}

export default useWidgetValue;
