import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { admin as initialState } from '../initialState';

import {
  getAdminUsers,
  putUser,
  postUser,
  deleteUser,
  resendAccountCreationEmail,
} from './_users';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearAdmin: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getAdminUsers,
      putUser,
      postUser,
      deleteUser,
      resendAccountCreationEmail,
    ]);
  },
});

const { clearAdmin } = actions;

// Export the reducer, either as a default or named export
export {
  clearAdmin,
  getAdminUsers,
  putUser,
  postUser,
  deleteUser,
  resendAccountCreationEmail,
};
export default reducer;
