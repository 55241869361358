import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import validateHexColor from './validateHexColor';

export function useCustomTheme() {
  const sitePrimary = useSelector(
    (state) => state.sites?.selected?.theme?.primary
  );
  const siteSecondary = useSelector(
    (state) => state.sites?.selected?.theme?.secondary
  );
  const orgPrimary = useSelector(
    (state) => state.organization?.default?.theme?.primary
  );
  const orgSecondary = useSelector(
    (state) => state.organization?.default?.theme?.secondary
  );

  const [primary, secondary] = useMemo(() => {
    const sitePrimValid = validateHexColor(sitePrimary);
    const siteSecValid = validateHexColor(siteSecondary);
    const orgPrimValid = validateHexColor(orgPrimary);
    const orgSecValid = validateHexColor(orgSecondary);

    const useSiteTheme = !!sitePrimValid || !!siteSecValid;
    return [
      useSiteTheme ? sitePrimValid : orgPrimValid,
      useSiteTheme ? siteSecValid : orgSecValid,
    ];
  }, [sitePrimary, siteSecondary, orgPrimary, orgSecondary]);

  return {
    primary,
    secondary,
  };
}

export default useCustomTheme;
