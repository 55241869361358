import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCmmsOptionsAPI } from '../../api';
import { buildAsyncReducers } from '../thunkTemplate';
import { cmms as initialState } from '../initialState';

const loadCmmsOptions = createAsyncThunk(
  'cmms/getOptions',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().cmms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const { options, info } = await getCmmsOptionsAPI();
    return { options, info };
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'cmms',
  initialState,
  reducers: {
    clearCmmsOptions: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [loadCmmsOptions]);
  },
});

const { clearCmmsOptions } = actions;

// Export the reducer, either as a default or named export
export { clearCmmsOptions, loadCmmsOptions };
export default reducer;
