import { useSelector } from 'react-redux';
import fastCompare from 'react-fast-compare';

export function useWidget(dashboardId, widgetId) {
  const widget = useSelector(
    (state) =>
      dashboardId && widgetId
        ? state.dashboards.dashboards[dashboardId]?.widgets.find(
            (w) => w.relation_id === widgetId
          )
        : {},
    fastCompare
  );
  return widget;
}

export default useWidget;
