import { useMemo } from 'react';
import { useTheme } from '@mui/styles';
import isTooLight from './isTooLight';

export function useThemeColor() {
  const theme = useTheme();

  const color = useMemo(() => {
    return isTooLight(theme.palette.secondary.main)
      ? isTooLight(theme.palette.primary.main)
        ? 'default'
        : 'primary'
      : 'secondary';
  }, [theme.palette.primary.main, theme.palette.secondary.main]);

  return color;
}

export default useThemeColor;
