import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get } from 'lodash';

import { generateLogoPutUrlAPI, deleteLogoObjectAPI } from '../../api';
import { addToastr, types } from '../../store/toastr';

export const generateLogoPutUrl = createAsyncThunk(
  'uploads/generateLogoPutUrl',
  async ({ orgId, contentType }, { dispatch }) => {
    try {
      dispatch(showLoading());
      return await generateLogoPutUrlAPI(orgId, contentType);
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to upload file',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteLogoObject = createAsyncThunk(
  'uploads/deleteLogoObject',
  async ({ orgId, fileId }, { dispatch }) => {
    try {
      dispatch(showLoading());
      await deleteLogoObjectAPI(orgId, fileId);

      // dispatch(
      //   addToastr({
      //     title: 'File deleted',
      //     type: types.success,
      //   })
      // );

      return;
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete file',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
