import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
// eslint-disable-next-line
import Symbol_observable from 'symbol-observable';

import App from './App';

import { unregister } from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

unregister();
