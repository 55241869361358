import { createAsyncThunk } from '@reduxjs/toolkit';

import { clearAdmin } from '../admin';
import { clearAdminStats } from '../adminStats';
import { clearOrganizationApps } from '../apps';
import { clearBulkEdit } from '../bulkEdit';
import { clearCmmsOptions } from '../cmms';
import { clearCredentials } from '../credentials';
import { clearDashboards } from '../dashboards';
import { clearDevices } from '../devices';
import { clearEdgeOptions } from '../edge';
import { clearOrganizations } from '../organization';
import { clearOrgStats } from '../orgStats';
import { clearResources } from '../resources';
import { clearSchedules } from '../schedules';
import { clearServiceSites } from '../serviceSites';
import { clearSites } from '../sites';
import { clearTrendCharts } from '../trendCharts';
import { clearTrends } from '../trends';
import { clearUploads } from '../uploads';
import { clearUser } from '../user';

export const clearAppStore = createAsyncThunk(
  'auth/clearAppStore',
  async (_, { dispatch }) => {
    dispatch(clearAdmin());
    dispatch(clearAdminStats());
    dispatch(clearOrganizationApps());
    dispatch(clearBulkEdit());
    dispatch(clearCmmsOptions());
    dispatch(clearCredentials());
    dispatch(clearDashboards());
    dispatch(clearDevices());
    dispatch(clearEdgeOptions());
    dispatch(clearOrganizations());
    dispatch(clearOrgStats());
    dispatch(clearResources());
    dispatch(clearSchedules());
    dispatch(clearServiceSites());
    dispatch(clearSites());
    dispatch(clearTrendCharts());
    dispatch(clearTrends());
    dispatch(clearUploads());
    dispatch(clearUser());
    return;
  }
);
