import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { apps as initialState } from '../initialState';

import {
  getOrganizationApps,
  putOrganizationApp,
  postOrganizationApp,
  deleteOrganizationApp,
  batchPutSiteApps,
} from './_apps';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    clearOrganizationApps: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationApps,
      putOrganizationApp,
      postOrganizationApp,
      deleteOrganizationApp,
      batchPutSiteApps,
    ]);
  },
});

const { clearOrganizationApps } = actions;

// Export the reducer, either as a default or named export
export {
  clearOrganizationApps,
  getOrganizationApps,
  putOrganizationApp,
  postOrganizationApp,
  deleteOrganizationApp,
  batchPutSiteApps,
};
export default reducer;
