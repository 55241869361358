import { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';
import ROUTES from '../../constants/routes';

export function useWorkspaceRedirect({ condition }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (condition) dispatch(push(ROUTES.AUTH.WORKSPACE));
  }, [condition]);
}

export default useWorkspaceRedirect;
