import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
  getOrganizationUsersAPI,
  postOrganizationUserAPI,
  putOrganizationUserRelationAPI,
  deleteOrganizationUserRelationAPI,
} from '../../api';
import { addToastr, types } from '../toastr';

export const getOrganizationUsers = createAsyncThunk(
  'organization/getOrganizationUsers',
  async (org_id, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().organization;
      const { item_id: orgId } = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const users = await getOrganizationUsersAPI(org_id ? org_id : orgId);
      return { users };
    } catch (err) {
      console.error(err);
    }
  }
);

export const postOrganizationUser = createAsyncThunk(
  'organization/postOrganizationUser',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      dispatch(showLoading());
      const email = data.email.toLowerCase();
      const users = await postOrganizationUserAPI(id, { ...data, email });
      dispatch(
        addToastr({
          title: 'User added to organization!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { users };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to add user to organization',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationUser = createAsyncThunk(
  'organization/putOrganizationUser',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      dispatch(showLoading());
      const users = await putOrganizationUserRelationAPI(id, data);
      dispatch(
        addToastr({
          title: "User's permissions updated!",
          type: types.success,
          message: get(data, 'name'),
        })
      );

      return { users };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: "Failed to update user's permissions",
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationUser = createAsyncThunk(
  'organization/deleteOrganizationUser',
  async ({ user_id, org_id }, { dispatch, getState, requestId }) => {
    try {
      dispatch(showLoading());
      const users = await deleteOrganizationUserRelationAPI(user_id, org_id);
      dispatch(
        addToastr({
          title: 'User removed from organization!',
          type: types.success,
        })
      );

      return { users };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to remove user from organization',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
