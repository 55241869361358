import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { isEqual, get } from 'lodash';

import { addToastr, types } from '../toastr';
import {
  batchDeleteOrganizationServiceSitesAPI,
  getOrganizationDetailsAPI,
  postOrganizationServiceSiteAPI,
  putOrganizationServiceSiteAPI,
  deleteOrganizationServiceSiteAPI,
} from '../../api';

export const getOrganizationServiceSites = createAsyncThunk(
  'serviceSites/getOrganizationResources',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().serviceSites;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());

    const { service_sites } = await getOrganizationDetailsAPI(
      organization.item_id,
      'service_sites',
      true
    );

    dispatch(hideLoading());

    return { serviceSites: service_sites };
  }
);

export const postOrganizationServiceSite = createAsyncThunk(
  'serviceSites/postOrganizationServiceSite',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        serviceSites: prevServiceSites,
      } = getState().serviceSites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const added = await postOrganizationServiceSiteAPI(orgId, credId, data);
      dispatch(
        addToastr({
          title: 'Service Site added!',
          type: types.success,
          message: `Added ${added.name}.`,
        })
      );

      const serviceSites = [...prevServiceSites, added];

      return { serviceSites };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add Service Site',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationServiceSite = createAsyncThunk(
  'serviceSites/putOrganizationServiceSite',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        serviceSites: prevServiceSites,
      } = getState().serviceSites;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const updated = await putOrganizationServiceSiteAPI(orgId, credId, data);
      dispatch(
        addToastr({
          title: 'Service Site updated!',
          type: types.success,
          message: `Updated ${updated.name}.`,
        })
      );

      const serviceSites = prevServiceSites.map((servSite) =>
        servSite.item_id === data.item_id &&
        servSite.relation_id === data.relation_id
          ? updated
          : servSite
      );

      return { serviceSites };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Service Site',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationServiceSite = createAsyncThunk(
  'organization/deleteOrganizationServiceSite',
  async ({ data }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().serviceSites;
    const initialServiceSites = getState().serviceSites.serviceSites;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const deleted = await deleteOrganizationServiceSiteAPI(
        orgId,
        credId,
        data.item_id
      );
      dispatch(
        addToastr({
          title: 'Service Site removed!',
          type: types.success,
          message: get(data, 'name'),
        })
      );

      const serviceSites = initialServiceSites.filter(
        (servSite) =>
          !(
            servSite.item_id === deleted.item_id &&
            servSite.relation_id === deleted.relation_id
          )
      );

      return { serviceSites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete Service Site',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchDeleteOrganizationServiceSites = createAsyncThunk(
  'serviceSites/batchDeleteOrganizationServiceSites',
  async (_, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().serviceSites;
      const initialServiceSites = getState().serviceSites.serviceSites;

      const selected = getState().bulkEdit.selected;

      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const deleted = await batchDeleteOrganizationServiceSitesAPI(
        organization.item_id,
        selected
      );
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Deleted ${deleted.length} ${
            deleted.length > 1 ? 'Service Sites' : 'Service Site'
          } from ${organization.name}.`,
        })
      );

      const deletedIds = deleted.map(
        (servSite) => `${servSite.item_id}|${servSite.relation_id}`
      );
      const serviceSites = initialServiceSites.filter(
        (servSite) =>
          !deletedIds.includes(`${servSite.item_id}|${servSite.relation_id}`)
      );

      return { serviceSites };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete Service Sites',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
