import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, isEqual, sortBy, pick } from 'lodash';
import fastCompare from 'react-fast-compare';

import { addToastr, types } from '../../store/toastr';
import {
  postOrganizationDashboardAPI,
  putOrganizationDashboardAPI,
  getOrganizationDetailsAPI,
  deleteOrganizationDashboardAPI,
} from '../../api';
import { setSelectedDashboard, updateDashboard } from '.';
import { isLayoutEqual } from '../../helpers/dashboards';

export const postOrganizationDashboard = createAsyncThunk(
  'dashboards/postOrganizationDashboard',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const added = await postOrganizationDashboardAPI(
        organization.item_id,
        data
      );
      dispatch(
        addToastr({
          title: 'Dashboard added!',
          type: types.success,
          message: `Added ${added.name} to ${organization.name}.`,
        })
      );

      dispatch(setSelectedDashboard(added));
      return {
        dashboards: {
          ...initialDashboards,
          [added.relation_id]: added,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add Dashboard',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const renameOrganizationDashboard = createAsyncThunk(
  'dashboards/renameOrganizationDashboard',
  async (
    { dashboardId, name, silent = true },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      // if (!silent && (loading !== true || requestId !== currentRequestId)) {
      //   return;
      // }
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      !silent && dispatch(showLoading());
      const updated = await putOrganizationDashboardAPI(organization.item_id, {
        item_id: organization.item_id,
        relation_id: dashboardId,
        name,
      });
      !silent &&
        dispatch(
          addToastr({
            title: 'Dashboard updated!',
            type: types.success,
            message: `Updated ${updated.name} for ${organization.name}.`,
          })
        );

      dispatch(setSelectedDashboard(updated));
      return {
        dashboards: {
          ...initialDashboards,
          [updated.relation_id]: updated,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Dashboard',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      !silent && dispatch(hideLoading());
    }
  }
);

export const toggleOrganizationDashboardEnabled = createAsyncThunk(
  'dashboards/toggleOrganizationDashboardEnabled',
  async ({ dashboardId, enabled }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updated = await putOrganizationDashboardAPI(organization.item_id, {
        item_id: organization.item_id,
        relation_id: dashboardId,
        enabled,
      });
      dispatch(
        addToastr({
          title: 'Dashboard updated!',
          type: types.success,
          message: `${updated.name} ${
            updated.enabled ? 'visible in' : 'hidden from'
          } Workspace.`,
        })
      );

      dispatch(setSelectedDashboard(updated));
      return {
        dashboards: {
          ...initialDashboards,
          [updated.relation_id]: updated,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Dashboard',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationDashboardLayout = createAsyncThunk(
  'dashboards/putOrganizationDashboardLayout',
  async ({ layout }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
        selected: dashboard,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const oldLayout = dashboard.layout;
      const newLayout = layout.map((l) => pick(l, Object.keys(oldLayout[0])));
      const equal =
        newLayout?.length && Object.keys(newLayout[0]).length
          ? isLayoutEqual(oldLayout, newLayout)
          : true;

      // dont PUT if equal
      if (equal) return;

      dispatch(
        updateDashboard({
          ...dashboard,
          layout: newLayout,
        })
      );

      const { item_id, relation_id } = dashboard;
      const updated = await putOrganizationDashboardAPI(organization.item_id, {
        item_id,
        relation_id,
        layout: newLayout,
      });

      dispatch(setSelectedDashboard(updated));
      return {
        dashboards: {
          ...initialDashboards,
          [updated.relation_id]: updated,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Dashboard',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    }
  }
);

export const deleteOrganizationDashboard = createAsyncThunk(
  'dashboards/deleteOrganizationDashboard',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const deleted = await deleteOrganizationDashboardAPI(
        data.item_id,
        data.relation_id
      );
      dispatch(
        addToastr({
          title: 'Dashboard deleted!',
          type: types.success,
          message: `Removed ${deleted.name} from ${organization.name}.`,
        })
      );

      const { [deleted.relation_id]: _, ...updated } = initialDashboards;

      return { dashboards: updated };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to delete Dashboard',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const getOrganizationDashboards = createAsyncThunk(
  'dashboards/getOrganizationDashboard',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().dashboards;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());
    const { dashboards } = await getOrganizationDetailsAPI(
      organization.item_id,
      'dashboards'
    );
    dispatch(hideLoading());

    return { dashboards };
  }
);
