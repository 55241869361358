import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { actions as toastrActions } from 'react-redux-toastr';
import { isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { buildAsyncReducers } from '../thunkTemplate';

const { add, remove } = toastrActions;

const icons = {
  success: ['fal', 'circle-check'],
  info: ['fal', 'circle-info'],
  warning: ['fal', 'triangle-exclamation'],
  light: ['fal', 'lightbulb'],
  error: ['fal', 'circle-exclamation'],
};

export const types = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  light: 'light',
  error: 'error',
};

const addToastr = createAsyncThunk(
  '@@toastr/add',
  (
    {
      title = '',
      message = '',
      type = 'success',
      position = 'bottom-right',
      // useBullets = true,
      options = {},
    },
    { dispatch }
  ) => {
    // Additional toastr options
    /*
    {
      attention?: boolean;
      className?: string;
      component?: Component | JSX.Element;
      icon?: JSX.Element;
      onCloseButtonClick?: () => void;
      onHideComplete?: () => void;
      onShowComplete?: () => void;
      onToastrClick?: () => void;
      progressBar?: boolean;
      removeOnHover?: boolean;
      showCloseButton?: boolean;
      timeOut?: number;
      transitionIn?: transitionInType;
      transitionOut?: transitionOutType;
      getState?: (state: ToastrState) => ToastrState;
    }
    */

    options.timeOut = 10000;
    options.icon = (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <FontAwesomeIcon
          icon={icons[type]}
          style={{
            height: '2.5em',
            width: '2.5em',
            margin: 'auto',
            marginBottom: '1em',
            marginTop: title ? '0.5em' : '1em',
          }}
        />
      </div>
    );

    message =
      message &&
      (isArray(message) ? (
        message.map((line, i) =>
          i === 0 ? (
            <Typography key={i} variant='subtitle1'>
              {line}
            </Typography>
          ) : (
            <Typography key={i} variant='subtitle1'>
              {`\u00A0\u00A0 ${line}`}
            </Typography>
          )
        )
      ) : (
        <Typography variant='subtitle1'>{message}</Typography>
      ));

    const toastId = uuidv4();
    const toastrOptions = {
      id: toastId,
      message: message,
      title: title,
      type,
      position,
      options,
    };

    dispatch(add(toastrOptions));

    // timeOut isn't removing the toasts, so do it manually.
    setTimeout(() => {
      dispatch(remove(toastId));
    }, options.timeOut);
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: '@@toastr',
  initialState: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [addToastr]);
  },
});

// Export the reducer, either as a default or named export
export { addToastr };
export default reducer;
