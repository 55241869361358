export const colors = {
  success: '#5CB85C',
  warning: '#F0AD4E',
  extremeWarning: '#FF7300',
  danger: '#DE6764',
  default: '#a7a7a7',
  disabled: 'rgba(0,0,0,0.38)',

  // generated from brand_primary
  // success: '#73BE27',
  // warning: '#E8A530',
  // danger: '#C91D1D',

  // generated from brand_secondary
  // success: '#7EC945',
  // warning: '#ECBA55',
  // danger: '#E14B2D',

  brand_primary: '#195E88', // veregy blue
  brand_secondary: '#73B043', // veregy green

  ultraLightGrey: '#f2f2f2',
  lightGrey: '#e8e8e8', // overrides veregy lightGrey
  darkGrey: '#474747',
  background: '#f8f8f8',

  // previously used
  // palette_1: '#375E97', // blue
  // palette_6: '#6FB98F', // sage-green
  // palette_7: '#CB0000', // red
  // palette_9: '#2797e3', // light-blue
};

export const chartColors = [
  '#195e88',
  '#007293',
  '#008492',
  '#009585',
  '#34a370',
  '#74ad58',
  '#aeb448',
  '#e9b44b',
];
