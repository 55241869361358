import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, pick, sortBy } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  putDashboardWidgetAPI,
  putOrganizationDashboardAPI,
  duplicateDashboardWidgetGroupAPI,
  deleteDashboardWidgetAPI,
} from '../../api';
import {
  findFirstAvailablePosition,
  isLayoutEqual,
} from '../../helpers/dashboards';
import {
  getOrganizationDashboardData,
  setDashboardData,
  setSelectedDashboard,
  updateDashboard,
} from '.';

export const addWidgetToGroup = createAsyncThunk(
  'dashboards/addWidgetToGroup',
  async (
    { dashboardId, groupId, widgetId },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const initialDashboard = initialDashboards[dashboardId];

      const initialWidgetLayout = initialDashboard.layout.find(
        (l) => l.i === widgetId
      );
      const { w, h } = initialWidgetLayout;

      const numCols = initialDashboard.layout.find((l) => l.i === groupId).w;

      const initialGroup = initialDashboard.widget_groups.find(
        (g) => g.relation_id === groupId
      );

      const { x, y } = findFirstAvailablePosition(
        initialGroup.layout,
        w,
        h,
        numCols
      );

      const widgetLayout = { i: widgetId, x, y, w, h };

      const body = {
        relation_id: initialGroup.relation_id,
        layout: [...initialGroup.layout, widgetLayout],
      };
      const dashboard = await putDashboardWidgetAPI(
        organization.item_id,
        dashboardId,
        body,
        true
      );

      const updatedDashboard = {
        ...dashboard,
        layout: dashboard.layout.filter((l) => l.i !== widgetId),
      };

      dispatch(setSelectedDashboard(updatedDashboard));

      return {
        dashboards: {
          ...initialDashboards,
          [dashboard.relation_id]: updatedDashboard,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add widget to group.',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const removeWidgetsFromGroup = createAsyncThunk(
  'dashboards/removeWidgetsFromGroup',
  async (
    { dashboardId, groupId, widgetIds = [], deleteGroup = false },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (!widgetIds.length) return;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const initialDashboard = initialDashboards[dashboardId];
      const group = initialDashboard.widget_groups.find(
        (g) => g.relation_id === groupId
      );
      const initialGroupLayout = group.layout;
      const movingWidgets = sortBy(
        initialGroupLayout.filter((l) => widgetIds.includes(l.i)),
        ['y', 'x']
      );

      const numCols = 24;
      let dashboardLayout = [...initialDashboard.layout];
      movingWidgets.forEach((widget) => {
        const { i, w, h } = widget;
        const { x, y } = findFirstAvailablePosition(
          dashboardLayout,
          w,
          h,
          numCols
        );
        const widgetLayout = { i, x, y, w, h };
        dashboardLayout = [...dashboardLayout, widgetLayout];
      });

      if (deleteGroup) {
        dashboardLayout = dashboardLayout.filter((l) => l.i !== groupId);
      }

      let dashboard = {};
      if (deleteGroup) {
        dashboard = await deleteDashboardWidgetAPI(
          organization.item_id,
          dashboardId,
          groupId,
          true
        );
      } else {
        const newGroupLayout = initialGroupLayout.filter(
          (l) => !widgetIds.includes(l.i)
        );
        const body = {
          relation_id: groupId,
          layout: newGroupLayout,
        };
        dashboard = await putDashboardWidgetAPI(
          organization.item_id,
          dashboardId,
          body,
          true
        );
      }

      const updatedDashboard = {
        ...dashboard,
        layout: dashboardLayout,
      };

      const { item_id, relation_id, layout } = updatedDashboard;
      putOrganizationDashboardAPI(organization.item_id, {
        item_id,
        relation_id,
        layout,
      });

      dispatch(setSelectedDashboard(updatedDashboard));
      dispatch(getOrganizationDashboardData({ dashboardId }));

      return {
        dashboards: {
          ...initialDashboards,
          [dashboard.relation_id]: updatedDashboard,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add widget to group.',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putWidgetGroupLayout = createAsyncThunk(
  'dashboards/putWidgetGroupLayout',
  async ({ groupId, layout }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
        selected: selectedDashboard,
      } = getState().dashboards;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      const dashboard = initialDashboards[selectedDashboard.relation_id];

      const oldLayout = dashboard.widget_groups.find(
        (group) => group.relation_id === groupId
      )?.layout;
      const newLayout = layout.map((l) => pick(l, Object.keys(oldLayout[0])));
      const equal =
        newLayout?.length && Object.keys(newLayout[0]).length
          ? isLayoutEqual(oldLayout, newLayout)
          : true;

      // dont PUT if equal
      if (equal) return;

      dispatch(
        updateDashboard({
          ...dashboard,
          widget_groups: dashboard.widget_groups.map((group) =>
            group.relation_id === groupId
              ? { ...group, layout: newLayout }
              : group
          ),
        })
      );

      const { item_id, relation_id } = dashboard;
      const updatedDashboard = await putDashboardWidgetAPI(
        item_id,
        relation_id,
        { relation_id: groupId, layout },
        true
      );

      dispatch(setSelectedDashboard(updatedDashboard));
      return {
        dashboards: {
          ...initialDashboards,
          [updatedDashboard.relation_id]: updatedDashboard,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update Dashboard',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    }
  }
);

export const duplicateWidgetGroup = createAsyncThunk(
  'dashboards/duplicateWidgetGroup',
  async ({ dashboardId, groupId }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const orgId = getState().organization.default.item_id;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const initialDashboard = initialDashboards[dashboardId];
      const initialLayout = initialDashboard.layout;

      const duplicateLayout = initialLayout.find((l) => l.i === groupId);

      const newWidgetWidth = duplicateLayout.w ?? 12;
      const newWidgetHeight = duplicateLayout.h ?? 6;
      const numCols = 24;

      const { x, y } = findFirstAvailablePosition(
        initialLayout,
        newWidgetWidth,
        newWidgetHeight,
        numCols
      );

      const newGroupLayout = {
        x: x,
        y: y,
        w: newWidgetWidth,
        h: newWidgetHeight,
      };

      const { dashboard, new_to_old_widget_id_map } =
        await duplicateDashboardWidgetGroupAPI(
          orgId,
          dashboardId,
          groupId,
          newGroupLayout
        );
      dispatch(
        addToastr({
          title: 'Widget group added!',
          type: types.success,
          // message: `Added ${widget.name} to ${dashboard.name}.`,
        })
      );

      dispatch(setSelectedDashboard(dashboard));

      const initialData = getState().dashboards.data[dashboardId];
      const newWidgetData = Object.entries(new_to_old_widget_id_map).reduce(
        (acc, [newId, existingId]) => {
          acc[newId] = initialData[existingId] ?? [];
          return acc;
        },
        {}
      );

      dispatch(
        setDashboardData({
          dashboardId,
          data: { ...initialData, ...newWidgetData },
        })
      );

      return {
        dashboards: {
          ...initialDashboards,
          [dashboard.relation_id]: dashboard,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add widget group',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);
