import { get, merge } from 'lodash';
import axios, { auth } from '../xhr_libs/axios';

/*
..#######..########...######......###....##....##.####.########....###....########.####..#######..##....##..######.
.##.....##.##.....##.##....##....##.##...###...##..##.......##....##.##......##.....##..##.....##.###...##.##....##
.##.....##.##.....##.##.........##...##..####..##..##......##....##...##.....##.....##..##.....##.####..##.##......
.##.....##.########..##...####.##.....##.##.##.##..##.....##....##.....##....##.....##..##.....##.##.##.##..######.
.##.....##.##...##...##....##..#########.##..####..##....##.....#########....##.....##..##.....##.##..####.......##
.##.....##.##....##..##....##..##.....##.##...###..##...##......##.....##....##.....##..##.....##.##...###.##....##
..#######..##.....##..######...##.....##.##....##.####.########.##.....##....##....####..#######..##....##..######.
*/

export const postOrganizationAPI = async (body) => {
  const { data } = await axios.post(`/organizations`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getOrganizationsAPI = async () => {
  const { data } = await axios.get(`/organizations`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationAPI = async (id, body) => {
  const { data } = await axios.put(`/organizations/${id}`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationAPI = async (id) => {
  const { data } = await axios.delete(`/organizations/${id}`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

const _getOrganizationDetailsPaginatedAPI = async (org_id, itemType) => {
  let items = [];
  let iterations = 0;
  let last_evaluated_key = null;
  while ((iterations === 0 || last_evaluated_key) && iterations < 20) {
    iterations++;
    const { data } = await axios.get(`/organizations/${org_id}/${itemType}`, {
      params: {
        last_evaluated_key: last_evaluated_key
          ? JSON.stringify(last_evaluated_key)
          : null,
        paginate: true,
      },
    });
    if (data.result === 'success') {
      const { value, last_evaluated_key: nextLastEvaluatedKey } = data;
      last_evaluated_key = nextLastEvaluatedKey;
      items = [...items, ...value];
    }
  }
  return { [itemType]: items };
};

export const getOrganizationDetailsAPI = async (
  org_id,
  itemType,
  paginate = false
) => {
  if (paginate) {
    return _getOrganizationDetailsPaginatedAPI(org_id, itemType);
  }

  const { data } = await axios.get(`/organizations/${org_id}/${itemType}`);
  if (data.result === 'success') {
    const { value } = data;
    return { [itemType]: value };
  }
};
/*
.##.....##..######..########.########...######.
.##.....##.##....##.##.......##.....##.##....##
.##.....##.##.......##.......##.....##.##......
.##.....##..######..######...########...######.
.##.....##.......##.##.......##...##.........##
.##.....##.##....##.##.......##....##..##....##
..#######...######..########.##.....##..######.
*/

export const resendAccountCreationEmailAPI = async (body) => {
  const { data } = await axios.post(
    `/users/${body.item_id}/resend-account-creation`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  } else {
    // TODO show Toastr with message
  }
};

export const postUserAPI = async (id, body) => {
  const { data } = await axios.post(`/users`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getUserAPI = async (user) => {
  const { data } = await axios.get(`/users/${get(user, 'username', '')}`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getUsersAPI = async () => {
  const { data } = await axios.get(`/users`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putUserAPI = async (id, body) => {
  const { data } = await axios.put(`/users/${id}`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putUserDefaultOrgAPI = async (id, org_id) => {
  const { data } = await axios.put(`/users/${id}/default_org`, {
    default_org: org_id,
  });
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteUserAPI = async (id) => {
  const { data } = await axios.delete(`/users/${id}`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const checkAccountReset = async (email) => {
  const { data } = await auth.get('/account-reset', {
    params: { email },
  });
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getOrganizationUsersAPI = async (id) => {
  const { data } = await axios.get(`/organizations/${id}/users`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putUserSubscriptionAPI = async (org_id, user_id, subscribed) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/users/${user_id}/subscribed`,
    { subscribed }
  );

  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const postOrganizationUserAPI = async (id, body) => {
  const org_id = get(body, 'org_id');
  const { data } = await axios.post(`/organizations/${org_id}/users`, body);

  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationUserRelationAPI = async (id, body) => {
  const org_id = get(body, 'org_id');
  const { data } = await axios.put(
    `/organizations/${org_id}/users/${id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationUserRelationAPI = async (user_id, org_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/users/${user_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
..######..##.....##.##.....##..######...........#######..########..########.####..#######..##....##..######.
.##....##.###...###.###...###.##....##.........##.....##.##.....##....##.....##..##.....##.###...##.##....##
.##.......####.####.####.####.##...............##.....##.##.....##....##.....##..##.....##.####..##.##......
.##.......##.###.##.##.###.##..######..#######.##.....##.########.....##.....##..##.....##.##.##.##..######.
.##.......##.....##.##.....##.......##.........##.....##.##...........##.....##..##.....##.##..####.......##
.##....##.##.....##.##.....##.##....##.........##.....##.##...........##.....##..##.....##.##...###.##....##
..######..##.....##.##.....##..######...........#######..##...........##....####..#######..##....##..######.
*/

export const getCmmsOptionsAPI = async () => {
  const { data } = await axios.get(`/cmms/get-options`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.########.########...######...########..........#######..########..########.####..#######..##....##..######.
.##.......##.....##.##....##..##...............##.....##.##.....##....##.....##..##.....##.###...##.##....##
.##.......##.....##.##........##...............##.....##.##.....##....##.....##..##.....##.####..##.##......
.######...##.....##.##...####.######...#######.##.....##.########.....##.....##..##.....##.##.##.##..######.
.##.......##.....##.##....##..##...............##.....##.##...........##.....##..##.....##.##..####.......##
.##.......##.....##.##....##..##...............##.....##.##...........##.....##..##.....##.##...###.##....##
.########.########...######...########..........#######..##...........##....####..#######..##....##..######.
*/

export const getEdgeOptionsAPI = async () => {
  const { data } = await axios.get(`/edge/get-options`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
..######...######..##.....##.########.########..##.....##.##.......########..######.
.##....##.##....##.##.....##.##.......##.....##.##.....##.##.......##.......##....##
.##.......##.......##.....##.##.......##.....##.##.....##.##.......##.......##......
..######..##.......#########.######...##.....##.##.....##.##.......######....######.
.......##.##.......##.....##.##.......##.....##.##.....##.##.......##.............##
.##....##.##....##.##.....##.##.......##.....##.##.....##.##.......##.......##....##
..######...######..##.....##.########.########...#######..########.########..######.
*/

export const postOrganizationScheduleAPI = async (org_id, body) => {
  // in the post function, body.relation_id will be just the code:
  const { data } = await axios.post(`/organizations/${org_id}/schedules`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getOrganizationSchedulesAPI = async (org_id) => {
  const itemType = 'schedules';
  const resObj = await _getOrganizationDetailsPaginatedAPI(org_id, itemType);
  return resObj[itemType];
};

export const getOrganizationScheduleResourcesAPI = async (org_id) => {
  let schedule_resources = [];
  let iterations = 0;
  let last_evaluated_key = null;
  while ((iterations === 0 || last_evaluated_key) && iterations < 30) {
    iterations++;
    const { data } = await axios.get(
      `/organizations/${org_id}/schedules/resources`,
      {
        params: {
          last_evaluated_key: last_evaluated_key
            ? JSON.stringify(last_evaluated_key)
            : null,
          paginate: true,
        },
      }
    );
    if (data.result === 'success') {
      const { value, last_evaluated_key: nextLastEvaluatedKey } = data;
      last_evaluated_key = nextLastEvaluatedKey;
      schedule_resources = merge({ ...schedule_resources }, value);
    }
  }
  return schedule_resources;
};

export const getOrganizationScheduleDevicesAPI = async (org_id) => {
  let schedule_devices = [];
  let iterations = 0;
  let last_evaluated_key = null;
  while ((iterations === 0 || last_evaluated_key) && iterations < 30) {
    iterations++;
    const { data } = await axios.get(
      `/organizations/${org_id}/schedules/devices`,
      {
        params: {
          last_evaluated_key: last_evaluated_key
            ? JSON.stringify(last_evaluated_key)
            : null,
          paginate: true,
        },
      }
    );
    if (data.result === 'success') {
      const { value, last_evaluated_key: nextLastEvaluatedKey } = data;
      last_evaluated_key = nextLastEvaluatedKey;
      schedule_devices = merge({ ...schedule_devices }, value);
    }
  }
  return schedule_devices;
};

export const getOrganizationScheduleEventsAPI = async (org_id) => {
  let events = [];
  let iterations = 0;
  let last_evaluated_key = null;
  while ((iterations === 0 || last_evaluated_key) && iterations < 30) {
    iterations++;
    const { data } = await axios.get(
      `/organizations/${org_id}/schedules/events`,
      {
        params: {
          last_evaluated_key: last_evaluated_key
            ? JSON.stringify(last_evaluated_key)
            : null,
        },
      }
    );
    if (data.result === 'success') {
      const { value, last_evaluated_key: nextLastEvaluatedKey } = data;
      last_evaluated_key = nextLastEvaluatedKey;
      events = [...events, ...value];
    }
  }
  return events;
};

export const forceOrganizationSchedulePollAPI = async (org_id) => {
  const { data } = await axios.get(`/organizations/${org_id}/schedules/poll`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationScheduleAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/schedules/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationScheduleAPI = async (org_id, sched_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/schedules/${sched_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
  BULK OPERATIONS
*/

export const batchDuplicateOrganizationSchedulesAPI = async (
  org_id,
  schedule_ids
) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/schedules/batch`,
    schedule_ids
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const batchDeleteOrganizationSchedulesAPI = async (
  org_id,
  schedule_ids
) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/schedules/batch`,
    { data: schedule_ids }
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
..######..########..########.########...######..
.##....##.##.....##.##.......##.....##.##....##.
.##.......##.....##.##.......##.....##.##.......
.##.......########..######...##.....##..######..
.##.......##...##...##.......##.....##.......##.
.##....##.##....##..##.......##.....##.##....##.
..######..##.....##.########.########...######..
*/

export const postOrganizationCredentialAPI = async (org_id, body) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/credentials?code=${body.service}`,
    body
  );
  if (data.result === 'success') {
    const { value: credentials, new: added } = data;
    return { credentials, added };
  }
};

export const getOrganizationCredentialsAPI = async (org_id) => {
  const { data } = await axios.get(`/organizations/${org_id}/credentials`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationCredentialAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/credentials/${body.relation_id}?code=${body.service}`,
    body
  );
  if (data.result === 'success') {
    const { value: credentials, updated } = data;
    return { credentials, updated };
  }
};

export const deleteOrganizationCredentialAPI = async (org_id, cred_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/credentials/${cred_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const pollOrganizationCredentialsAPI = async (org_id) => {
  const { data } = await axios.get(`/organizations/${org_id}/credentials/poll`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getOrganizationCredentialStatusAPI = async (org_id, cred_id) => {
  const { data } = await axios.get(
    `/organizations/${org_id}/credentials/${cred_id}/status`
  );
  if (data.result === 'success') {
    const { value: credentials, updated } = data;
    return { credentials, updated };
  }
};

export const getOrganizationCredentialSecretAPI = async (org_id, cred_id) => {
  const { data } = await axios.get(
    `/organizations/${org_id}/credentials/${cred_id}/secret`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getEventPollingOptionsAPI = async (org_id, cred_id) => {
  const { data } = await axios.get(
    `/organizations/${org_id}/credentials/${cred_id}/events/options`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const getWorkOrderRequestTypeOptionsAPI = async (org_id, cred_id) => {
  const response = await axios.get(
    `/organizations/${org_id}/credentials/${cred_id}/work_orders/options`
  );
  return response;
};

export const getWorkOrderCustomFieldOptionsAPI = async (
  org_id,
  cred_id,
  requestTypeID
) => {
  const response = await axios.get(
    `/organizations/${org_id}/credentials/${cred_id}/work_orders/options`,
    { params: { requestTypeID } }
  );
  return response;
};

export const postCredentialWorkOrderTemplateAPI = async (
  org_id,
  cred_id,
  body
) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/credentials/${cred_id}/templates`,
    body
  );
  if (data.result === 'success') {
    const { value: templates, new: added } = data;
    return templates;
  }
};

export const putCredentialWorkOrderTemplateAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/credentials/${body.item_id}/templates/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value: templates, updated } = data;
    return templates;
  }
};

export const deleteCredentialWorkOrderTemplateAPI = async (
  org_id,
  cred_id,
  template_id
) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/credentials/${cred_id}/templates/${template_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
....###....########..########...######.
...##.##...##.....##.##.....##.##....##
..##...##..##.....##.##.....##.##......
.##.....##.########..########...######.
.#########.##........##..............##
.##.....##.##........##........##....##
.##.....##.##........##.........######.
*/

export const getOrganizationAppsAPI = async (org_id) => {
  const { data } = await axios.get(`/organizations/${org_id}/apps`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationAppAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/apps/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const postOrganizationAppAPI = async (org_id, body) => {
  const { data } = await axios.post(`/organizations/${org_id}/apps`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationAppAPI = async (org_id, app_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/apps/${app_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const batchPutSiteAppsAPI = async (org_id, app_updates) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/sites/apps/batch`,
    app_updates
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.####.##....##.##.....##.####.########.########.....######...#######..########..########..######.
..##..###...##.##.....##..##.....##....##..........##....##.##.....##.##.....##.##.......##....##
..##..####..##.##.....##..##.....##....##..........##.......##.....##.##.....##.##.......##......
..##..##.##.##.##.....##..##.....##....######......##.......##.....##.##.....##.######....######.
..##..##..####..##...##...##.....##....##..........##.......##.....##.##.....##.##.............##
..##..##...###...##.##....##.....##....##..........##....##.##.....##.##.....##.##.......##....##
.####.##....##....###....####....##....########.....######...#######..########..########..######.
*/

export const getOrganizationInviteCodesAPI = async (org_id) => {
  const { data } = await axios.get(`/organizations/${org_id}/invites`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const postOrganizationInviteCodeAPI = async (org_id, body) => {
  const { data } = await axios.post(`/organizations/${org_id}/invites`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationInviteCodeAPI = async (org_id, invite_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/invites/${invite_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationInviteCodeAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/invites/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const verifyOrganizationInviteCodeAPI = async (invite_code) => {
  const { data } = await axios.post('/verify-invite', { invite_code });
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
..######..####.########.########..######.
.##....##..##.....##....##.......##....##
.##........##.....##....##.......##......
..######...##.....##....######....######.
.......##..##.....##....##.............##
.##....##..##.....##....##.......##....##
..######..####....##....########..######.
*/

export const getOrganizationSitesAPI = async (org_id) => {
  const { data } = await axios.get(`/organizations/${org_id}/sites`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const postOrganizationSiteAPI = async (org_id, body) => {
  const { data } = await axios.post(`/organizations/${org_id}/sites`, body);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationSiteAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/sites/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationSiteAPI = async (org_id, site_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/sites/${site_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.##.....##.########..##........#######.....###....########...######.
.##.....##.##.....##.##.......##.....##...##.##...##.....##.##....##
.##.....##.##.....##.##.......##.....##..##...##..##.....##.##......
.##.....##.########..##.......##.....##.##.....##.##.....##..######.
.##.....##.##........##.......##.....##.#########.##.....##.......##
.##.....##.##........##.......##.....##.##.....##.##.....##.##....##
..#######..##........########..#######..##.....##.########...######.
*/

/* Files */

export const generatePutUrlAPI = async (orgId, fileItem, contentType) => {
  const presignOptions = {
    params: {
      ContentType: contentType,
    },
    headers: {
      ContentType: contentType,
      Accept: 'application/json',
    },
  };
  const { data } = await axios.put(
    `/organizations/${orgId}/uploads/files`,
    fileItem,
    presignOptions
  );

  if (data.result === 'success') {
    const { value: signed_url, new: added } = data;
    return { signed_url, added };
  }
};

export const generateGetUrlAPI = async (orgId, fileId, contentDisposition) => {
  const { data } = await axios.get(
    `/organizations/${orgId}/uploads/files/${fileId}`,
    { params: { content_disposition: contentDisposition } }
  );

  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteUploadObjectAPI = async (orgId, fileId) => {
  const { data } = await axios.delete(
    `/organizations/${orgId}/uploads/files/${fileId}`
  );

  if (data.result === 'success') {
    const { value: files, deleted } = data;
    return { files, deleted };
  }
};

export const putUploadFileAPI = async (orgId, fileId, body) => {
  const { data } = await axios.put(
    `/organizations/${orgId}/uploads/files/${fileId}`,
    body
  );

  if (data.result === 'success') {
    const { value: files, updated } = data;
    return { files, updated };
  }
};

export const batchDeleteUploadObjectsAPI = async (orgId, fileIds) => {
  const fileIdsStr = fileIds.join(',');
  const { data } = await axios.delete(
    `/organizations/${orgId}/uploads/files/batch/${fileIdsStr}`
  );

  if (data.result === 'success') {
    const { value: files, deleted } = data;
    return { files, deleted };
  }
};

export const batchPutUploadFilesAPI = async (orgId, fileItems) => {
  const { data } = await axios.put(
    `/organizations/${orgId}/uploads/files/batch`,
    fileItems
  );

  if (data.result === 'success') {
    const { value: files, updated } = data;
    return { files, updated };
  }
};

/* Folders */

export const deleteOrganizationFolderAPI = async (orgId, folderId) => {
  const { data } = await axios.delete(
    `/organizations/${orgId}/uploads/folders/${folderId}`
  );

  if (data.result === 'success') {
    const { value: folders, deleted } = data;
    return { folders, deleted };
  }
};

export const postOrganizationFolderAPI = async (orgId, body) => {
  const { data } = await axios.post(
    `/organizations/${orgId}/uploads/folders`,
    body
  );

  if (data.result === 'success') {
    const { value: folders, updated } = data;
    return { folders, updated };
  }
};

export const putOrganizationFolderAPI = async (orgId, folderId, body) => {
  const { data } = await axios.put(
    `/organizations/${orgId}/uploads/folders/${folderId}`,
    body
  );

  if (data.result === 'success') {
    const { value: folders, updated } = data;
    return { folders, updated };
  }
};

/* Files and Folders */

export const batchMoveOrganizationFilesAndFoldersAPI = async (
  orgId,
  parent,
  folder,
  items
) => {
  const { data } = await axios.put(
    `/organizations/${orgId}/uploads/move/batch`,
    {
      parent,
      folder,
      items,
    }
  );
  if (data.result === 'success') {
    const { folders, files } = data.value;
    return { folders, files };
  }
};

export const batchDownloadFilesAndFoldersAPI = async (orgId, fileItems) => {
  const { data } = await axios.post(
    `/organizations/${orgId}/uploads/download`,
    fileItems
  );

  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const batchDeleteFilesAndFoldersAPI = async (
  orgId,
  fileAndFolderIds
) => {
  const fileAndFolderIdsStr = fileAndFolderIds.join(',');
  const { data } = await axios.delete(
    `/organizations/${orgId}/uploads/batch/${fileAndFolderIdsStr}`
  );

  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/* Org and Site Logos */

export const generateLogoPutUrlAPI = async (orgId, contentType) => {
  const presignOptions = {
    params: {
      ContentType: contentType,
    },
    headers: {
      ContentType: contentType,
      Accept: 'application/json',
    },
  };
  const { data } = await axios.get(`/logos/${orgId}`, presignOptions);

  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteLogoObjectAPI = async (orgId, fileId) => {
  const { data } = await axios.delete(`/logos/${orgId}/${fileId}`);

  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
..######..########....###....########..######.
.##....##....##......##.##......##....##....##
.##..........##.....##...##.....##....##......
..######.....##....##.....##....##.....######.
.......##....##....#########....##..........##
.##....##....##....##.....##....##....##....##
..######.....##....##.....##....##.....######.
*/
export const getAdminStatsAPI = async () => {
  const [{ data: orgData }, { data: eventData }] = await Promise.all([
    axios.get('/statistics'),
    axios.get('/statistics/events'),
  ]);

  if (orgData.result === 'success' && eventData.result === 'success') {
    const value = { ...orgData.value, eventsStats: eventData.value };
    return value;
  }
};

export const getOrganizationEventsStatsAPI = async (org_id) => {
  const { data } = await axios.get(
    `/organizations/${org_id}/statistics/events`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.########..########.##.....##.####..######..########..######.
.##.....##.##.......##.....##..##..##....##.##.......##....##
.##.....##.##.......##.....##..##..##.......##.......##......
.##.....##.######...##.....##..##..##.......######....######.
.##.....##.##........##...##...##..##.......##.............##
.##.....##.##.........##.##....##..##....##.##.......##....##
.########..########....###....####..######..########..######.
*/

export const putOrganizationDeviceAPI = async (org_id, cred_id, device) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/credentials/${cred_id}/devices/${device.item_id}`,
    device
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const postOrganizationDeviceAPI = async (org_id, cred_id, device) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/credentials/${cred_id}/devices`,
    device
  );
  if (data.result === 'success') {
    const { value: device, trends } = data;
    return { device, trends };
  }
};

export const deleteOrganizationDeviceAPI = async (
  org_id,
  cred_id,
  device_id
) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/credentials/${cred_id}/devices/${device_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const batchDeleteOrganizationDevicesAPI = async (org_id, device_ids) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/devices/batch`,
    { data: device_ids }
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const batchPutOrganizationDevicesAPI = async (
  org_id,
  device_updates
) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/devices/batch`,
    device_updates
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
..######..########.########..##.....##.####..######..########..........######..####.########.########..######.
.##....##.##.......##.....##.##.....##..##..##....##.##...............##....##..##.....##....##.......##....##
.##.......##.......##.....##.##.....##..##..##.......##...............##........##.....##....##.......##......
..######..######...########..##.....##..##..##.......######...#######..######...##.....##....######....######.
.......##.##.......##...##....##...##...##..##.......##.....................##..##.....##....##.............##
.##....##.##.......##....##....##.##....##..##....##.##...............##....##..##.....##....##.......##....##
..######..########.##.....##....###....####..######..########..........######..####....##....########..######.
*/

export const postOrganizationServiceSiteAPI = async (
  org_id,
  cred_id,
  service_site
) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/credentials/${cred_id}/service_sites`,
    service_site
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationServiceSiteAPI = async (
  org_id,
  cred_id,
  service_site
) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/credentials/${cred_id}/service_sites/${service_site.item_id}`,
    service_site
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationServiceSiteAPI = async (
  org_id,
  cred_id,
  service_site_id
) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/credentials/${cred_id}/service_sites/${service_site_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const batchDeleteOrganizationServiceSitesAPI = async (
  org_id,
  service_site_ids
) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/service_sites/batch`,
    { data: service_site_ids }
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.########..########..######...#######..##.....##.########...######..########..######.
.##.....##.##.......##....##.##.....##.##.....##.##.....##.##....##.##.......##....##
.##.....##.##.......##.......##.....##.##.....##.##.....##.##.......##.......##......
.########..######....######..##.....##.##.....##.########..##.......######....######.
.##...##...##.............##.##.....##.##.....##.##...##...##.......##.............##
.##....##..##.......##....##.##.....##.##.....##.##....##..##....##.##.......##....##
.##.....##.########..######...#######...#######..##.....##..######..########..######.
*/

export const putOrganizationResourceAPI = async (org_id, cred_id, resource) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/credentials/${cred_id}/resources/${resource.item_id}`,
    resource
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.########.####..######..##....##.########.########..######.
....##.....##..##....##.##...##..##..........##....##....##
....##.....##..##.......##..##...##..........##....##......
....##.....##..##.......#####....######......##.....######.
....##.....##..##.......##..##...##..........##..........##
....##.....##..##....##.##...##..##..........##....##....##
....##....####..######..##....##.########....##.....######.
*/

export const batchDeleteOrganizationTicketsAPI = async (org_id, selected) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/tickets/batch`,
    { data: selected }
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const pollOrganizationWorkOrdersAPI = async (org_id) => {
  const { data } = await axios.get(`/organizations/${org_id}/tickets/poll`);
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.########.########..########.##....##.########...######.
....##....##.....##.##.......###...##.##.....##.##....##
....##....##.....##.##.......####..##.##.....##.##......
....##....########..######...##.##.##.##.....##..######.
....##....##...##...##.......##..####.##.....##.......##
....##....##....##..##.......##...###.##.....##.##....##
....##....##.....##.########.##....##.########...######.
*/

export const getOrganizationTrendDataAPI = async (
  org_id,
  trend_items,
  start,
  end,
  number_function
) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/trends/data/${start}/${end}`,
    trend_items,
    { params: { number_function } }
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationTrendAPI = async (org_id, cred_id, trend_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/credentials/${cred_id}/trends/${trend_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const batchDeleteOrganizationTrendsAPI = async (org_id, trend_ids) => {
  const { data } = await axios.delete(`/organizations/${org_id}/trends/batch`, {
    data: trend_ids,
  });
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.########.########..########.##....##.########...........######..##.....##....###....########..########..######.
....##....##.....##.##.......###...##.##.....##.........##....##.##.....##...##.##...##.....##....##....##....##
....##....##.....##.##.......####..##.##.....##.........##.......##.....##..##...##..##.....##....##....##......
....##....########..######...##.##.##.##.....##.#######.##.......#########.##.....##.########.....##.....######.
....##....##...##...##.......##..####.##.....##.........##.......##.....##.#########.##...##......##..........##
....##....##....##..##.......##...###.##.....##.........##....##.##.....##.##.....##.##....##.....##....##....##
....##....##.....##.########.##....##.########...........######..##.....##.##.....##.##.....##....##.....######.
*/

export const postOrganizationTrendChartAPI = async (
  org_id,
  trend_chart,
  widget
) => {
  const { data } = await axios.post(`/organizations/${org_id}/trend_charts`, {
    trend_chart,
    widget,
  });
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const putOrganizationTrendChartAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/trend_charts/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

export const deleteOrganizationTrendChartAPI = async (
  org_id,
  trend_charts_id
) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/trend_charts/${trend_charts_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};

/*
.########.....###.....######..##.....##.########...#######.....###....########..########...######.
.##.....##...##.##...##....##.##.....##.##.....##.##.....##...##.##...##.....##.##.....##.##....##
.##.....##..##...##..##.......##.....##.##.....##.##.....##..##...##..##.....##.##.....##.##......
.##.....##.##.....##..######..#########.########..##.....##.##.....##.########..##.....##..######.
.##.....##.#########.......##.##.....##.##.....##.##.....##.#########.##...##...##.....##.......##
.##.....##.##.....##.##....##.##.....##.##.....##.##.....##.##.....##.##....##..##.....##.##....##
.########..##.....##..######..##.....##.########...#######..##.....##.##.....##.########...######.
*/

export const postOrganizationDashboardAPI = async (org_id, body) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/dashboards`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value; // returns the new dashboard
  }
};

export const putOrganizationDashboardAPI = async (org_id, body) => {
  const { data } = await axios.put(
    `/organizations/${org_id}/dashboards/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value; // returns the updated dashboard
  }
};

export const deleteOrganizationDashboardAPI = async (org_id, dashboard_id) => {
  const { data } = await axios.delete(
    `/organizations/${org_id}/dashboards/${dashboard_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value; // returns the deleted dashboard
  }
};

export const postDashboardWidgetAPI = async (
  org_id,
  dashboard_id,
  widget,
  layout,
  group_id = null,
  isWidgetGroup = false
) => {
  const item_type = isWidgetGroup ? 'widget_groups' : 'widgets';
  const { data } = await axios.post(
    `/organizations/${org_id}/dashboards/${dashboard_id}/${item_type}`,
    { widget, layout, group_id }
  );
  if (data.result === 'success') {
    const { value: dashboard, new: widget } = data;
    return { dashboard, widget }; // returns the updated dashboard and the new widget
  }
};

export const duplicateDashboardWidgetGroupAPI = async (
  org_id,
  dashboard_id,
  group_id,
  layout
) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/dashboards/${dashboard_id}/widget_groups/${group_id}/duplicate`,
    { layout }
  );
  if (data.result === 'success') {
    const { value: dashboard, new: new_to_old_widget_id_map } = data;
    return { dashboard, new_to_old_widget_id_map }; // returns the updated dashboard and the new widget
  }
};

export const putDashboardWidgetAPI = async (
  org_id,
  dashboard_id,
  body,
  isWidgetGroup = false
) => {
  const item_type = isWidgetGroup ? 'widget_groups' : 'widgets';
  const { data } = await axios.put(
    `/organizations/${org_id}/dashboards/${dashboard_id}/${item_type}/${body.relation_id}`,
    body
  );
  if (data.result === 'success') {
    const { value } = data;
    return value; // returns the updated dashboard
  }
};

export const deleteDashboardWidgetAPI = async (
  org_id,
  dashboard_id,
  widget_id,
  isWidgetGroup = false
) => {
  const item_type = isWidgetGroup ? 'widget_groups' : 'widgets';
  const { data } = await axios.delete(
    `/organizations/${org_id}/dashboards/${dashboard_id}/${item_type}/${widget_id}`
  );
  if (data.result === 'success') {
    const { value } = data;
    return value; // returns the updated dashboard
  }
};

export const getOrganizationDashboardWidgetDataAPI = async (
  org_id,
  dashboard_id,
  widget_id,
  trend_items,
  start,
  end,
  timezone = null,
  number_function = null
) => {
  const { data } = await axios.post(
    `/organizations/${org_id}/dashboards/${dashboard_id}/widgets/${widget_id}/data/${start}/${end}`,
    trend_items,
    { params: { timezone, number_function } }
  );
  if (data.result === 'success') {
    const { value } = data;
    return value;
  }
};
