import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, isEqual } from 'lodash';

import { addToastr, types } from '../toastr';
import {
  postOrganizationTrendChartAPI,
  getOrganizationDetailsAPI,
  deleteOrganizationTrendChartAPI,
} from '../../api';

export const postOrganizationTrendChart = createAsyncThunk(
  'trendCharts/postOrganizationTrendChart',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        trendCharts: initialTrendCharts,
      } = getState().trendCharts;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const added = await postOrganizationTrendChartAPI(
        organization.item_id,
        data.trend_chart,
        data.widget
      );
      dispatch(
        addToastr({
          title: 'Chart saved!',
          type: types.success,
          message: `Added ${added.name} to ${organization.name}.`,
        })
      );

      const trendCharts = [...initialTrendCharts, added];

      return { trendCharts };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to save chart',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationTrendChart = createAsyncThunk(
  'trendCharts/deleteOrganizationTrendChart',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        trendCharts: initialTrendCharts,
      } = getState().trendCharts;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const deleted = await deleteOrganizationTrendChartAPI(
        data.item_id,
        data.relation_id
      );
      dispatch(
        addToastr({
          title: 'Chart deleted!',
          type: types.success,
          message: `Removed ${deleted.name} from ${organization.name}.`,
        })
      );

      const trendCharts = initialTrendCharts.filter(
        (chart) => chart.relation_id !== deleted.relation_id
      );

      return { trendCharts };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to delete chart',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const getOrganizationTrendCharts = createAsyncThunk(
  'trendCharts/getOrganizationTrendCharts',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().trendCharts;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());
    const { trend_charts } = await getOrganizationDetailsAPI(
      organization.item_id,
      'trend_charts'
    );
    dispatch(hideLoading());

    return { trendCharts: trend_charts };
  }
);
