import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { isEqual, get } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  getOrganizationDetailsAPI,
  getOrganizationTrendDataAPI,
  deleteOrganizationTrendAPI,
  batchDeleteOrganizationTrendsAPI,
} from '../../api';

export const getOrganizationTrends = createAsyncThunk(
  'trends/getOrganizationTrends',
  async (org, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().trends;
    const organization = org || getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    dispatch(showLoading());

    const { trends } = await getOrganizationDetailsAPI(
      organization.item_id,
      'trends',
      true
    );

    dispatch(hideLoading());

    return { trends };
  }
);

export const getOrganizationTrendData = createAsyncThunk(
  'trends/getOrganizationTrendData',
  async (
    { trendItems, range, widgetFunction },
    { dispatch, getState, requestId }
  ) => {
    const { currentRequestId, loading } = getState().trends;
    const organization = getState().organization.default;

    if (
      isEqual(organization, {}) ||
      loading !== true ||
      requestId !== currentRequestId
    ) {
      return;
    }

    const { start, end } = range;

    dispatch(showLoading());

    try {
      const data = await getOrganizationTrendDataAPI(
        organization.item_id,
        trendItems,
        start,
        end,
        widgetFunction
      );

      if (data.length === 0) {
        dispatch(
          addToastr({
            title: 'No data was found for the current selection',
            type: types.warning,
          })
        );
      }

      return { data };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationTrend = createAsyncThunk(
  'trends/deleteOrganizationTrend',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        trends: prevTrends,
      } = getState().trends;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const [orgId, credId] = data.relation_id.split('#');

      const deleted = await deleteOrganizationTrendAPI(
        orgId,
        credId,
        data.item_id
      );
      dispatch(
        addToastr({
          title: 'Trend removed!',
          type: types.success,
          message: get(deleted, 'name'),
        })
      );

      const trends = prevTrends.filter(
        (trend) =>
          !(
            trend.item_id === deleted.item_id &&
            trend.relation_id === deleted.relation_id
          )
      );

      return { trends };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to remove Trend',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const batchDeleteOrganizationTrends = createAsyncThunk(
  'trends/batchDeleteOrganizationTrends',
  async (_, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        trends: initialTrends,
      } = getState().trends;

      const selected = getState().bulkEdit.selected;

      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const deleted = await batchDeleteOrganizationTrendsAPI(
        organization.item_id,
        selected
      );
      dispatch(
        addToastr({
          title: 'Success!',
          type: types.success,
          message: `Deleted ${deleted.length} ${
            deleted.length > 1 ? 'trends' : 'trend'
          } from ${organization.name}.`,
        })
      );

      const deletedIds = deleted.map(
        (trend) => `${trend.item_id}|${trend.relation_id}`
      );
      const trends = initialTrends.filter(
        (trend) => !deletedIds.includes(`${trend.item_id}|${trend.relation_id}`)
      );

      return { trends };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete Trends',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
