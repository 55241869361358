import { useSelector } from 'react-redux';
import fastCompare from 'react-fast-compare';

export function useWidgetLayout(widgetId, groupId = null) {
  const dashboardId = useSelector(
    (state) => state.dashboards.selected.relation_id
  );

  const layout = useSelector((state) => {
    const dashboard = state.dashboards.dashboards[dashboardId];
    const parent = groupId
      ? dashboard?.widget_groups.find((group) => group.relation_id === groupId)
      : dashboard;
    const gridLayout = parent?.layout;
    return gridLayout.find((l) => l.i === widgetId);
  }, fastCompare);

  return layout;
}

export default useWidgetLayout;
