import { get } from 'lodash';

export const BASE_NAME = Object.freeze('orchestratehub');

const getLocalStorageObject = (objName) => {
  if (!localStorage.getItem(objName))
    localStorage.setItem(objName, JSON.stringify({}));
  return JSON.parse(localStorage.getItem(objName));
};

const setLocalStorageObject = (objName, obj) =>
  localStorage.setItem(objName, JSON.stringify(obj));

export const getLocalStorageObjectValue = (objName, key) => {
  const localStorageObj = getLocalStorageObject(objName);
  return get(localStorageObj, key);
};

export const setLocalStorageObjectValue = (objName, key, value) => {
  const localStorageObj = getLocalStorageObject(objName);
  setLocalStorageObject(objName, { ...localStorageObj, [key]: value });
};
