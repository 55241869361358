export const veregy = {
  colors: {
    blue: 'rgba(25, 94, 136, 1)',
    lightBlue: 'rgba(147, 183, 190, 1)',
    green: 'rgba(115, 176, 67, 1)',
    grey: 'rgba(92, 92, 92, 1)',
    lightGrey: 'rgba(180, 180, 180, 1)',
    orange: 'rgba(233, 180, 75, 1)',
    purple: 'rgba(85,79,125, 1)',
    // colors below are not from marketing
    lightPurple: '#928cb7',
  },
  typography: {
    fontFamily: "'Arial', 'Helvetica', sans-serif",
    // fontFamily: ['Arial', 'Helvetica', 'sans-serif'],
    // fontSize: 16,
    // h1: {
    //   fontSize: 56,
    // },
    // h2: {
    //   fontSize: 40,
    // },
    // h3: {
    //   fontSize: 24,
    // },
    // lineHeight: '1.4em',
    // '@media (max-width:600px)': {
    //   fontSize: 14,
    //   h1: {
    //     fontSize: 36,
    //   },
    //   h2: {
    //     fontSize: 24,
    //   },
    //   h3: {
    //     fontSize: 20,
    //   },
    //   '@media (max-width:960px)': {
    //     fontSize: 15,
    //     h1: {
    //       fontSize: 46,
    //     },
    //     h2: {
    //       fontSize: 32,
    //     },
    //     h3: {
    //       fontSize: 22,
    //     },
    //   },
    // h1: {
    //   fontSize: {
    //     d: '56px',
    //     t: '46px',
    //     m: '36px',
    //   },
    //   spacing: '2px',
    //   lineHeight: '1.4em',
    // },
    // h2: {
    //   fontSize: {
    //     d: '40px',
    //     t: '32px',
    //     m: '24px',
    //   },
    //   spacing: '2px',
    //   lineHeight: '1.4em',
    // },
    // h3: {
    //   fontSize: {
    //     d: '24px',
    //     t: '22px',
    //     m: '20px',
    //   },
    //   spacing: '2px',
    //   lineHeight: '1.4em',
    // },
    // text: {
    //   fontSize: {
    //     d: '16px',
    //     t: '15px',
    //     m: '14px',
    //   },
    //   spacing: '0px',
    //   lineHeight: '1.6em',
    // },
  },
};
