import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOrgRedirect } from '../../store/appSettings';

export function useOrganizationRedirect(history) {
  const dispatch = useDispatch();

  const search = history?.location?.search;

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const orgRedirect = queryParams.get('orgRedirect');
    if (orgRedirect) {
      // update value in store
      dispatch(setOrgRedirect(orgRedirect));
      // remove query param
      queryParams.delete('orgRedirect');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [search]);
}

export default useOrganizationRedirect;
