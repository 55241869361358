import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, find } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  getUsersAPI,
  putUserAPI,
  postUserAPI,
  deleteUserAPI,
  resendAccountCreationEmailAPI,
} from '../../api';
import { setUserInfo } from '../user';
import { getOrganizationUsers } from '../organization';

export const getAdminUsers = createAsyncThunk(
  'admin/getAdminUsers',
  async (user, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const { user: currentUser } = getState().user;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      // Determine what to do with the user
      if (get(user || currentUser, 'super_user')) {
        let users = await getUsersAPI();
        return { users };
      }
      return {};
    } catch (err) {
      console.error(err);
    }
  }
);

export const putUser = createAsyncThunk(
  'admin/putUser',
  async ({ id, data, toast }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const requestingUser = getState().user.user;

      const requestingUserId = requestingUser.item_id;
      const super_user = get(requestingUser, 'super_user', false);

      const title = toast?.title;
      const message = toast?.message;

      // User can update their user or be a super user to update actual user data
      if (
        (!super_user && id !== requestingUserId) ||
        loading !== true ||
        requestId !== currentRequestId
      ) {
        return;
      }

      dispatch(showLoading());
      const users = await putUserAPI(id, data);

      // update current user
      const currentUser = find(users, { item_id: requestingUserId });
      dispatch(setUserInfo(currentUser));

      dispatch(getOrganizationUsers());

      dispatch(
        addToastr({
          title: title || 'User updated!',
          type: types.success,
          message: message || get(data, 'name'),
        })
      );
      return { users };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update User',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const postUser = createAsyncThunk(
  'admin/postUser',
  async ({ id, data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const { user } = getState().user;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const email = data.email.toLowerCase();
      const users = await postUserAPI(id, { ...data, email });

      dispatch(getOrganizationUsers());

      dispatch(
        addToastr({
          title: 'User created!',
          type: types.success,
          message: get(data, 'name'),
        })
      );
      return { users };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to create User',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteUser = createAsyncThunk(
  'admin/deleteUser',
  async ({ id }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const { user } = getState().user;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }
      dispatch(showLoading());
      const users = await deleteUserAPI(id);

      dispatch(getOrganizationUsers());

      dispatch(
        addToastr({
          title: 'User deleted!',
          type: types.success,
        })
      );
      return { users };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to delete User',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const resendAccountCreationEmail = createAsyncThunk(
  'admin/resendAccountCreationEmail',
  async ({ data }, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading } = getState().admin;
      const { user } = getState().user;
      const super_user = get(user, 'super_user', false);

      if (!super_user || loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let users = await resendAccountCreationEmailAPI(data);
      dispatch(
        addToastr({
          title: 'Account creation email resent',
          type: types.success,
          message: get(data, 'name'),
        })
      );
      return { users };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to resend account creation email',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
