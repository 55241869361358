import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import ReactLoader from 'react-loading';

import logo from '../assets/images/orchestrate-by-veregy-logo.svg';

const Root = styled('div')({ zIndex: 99999 });

const center = {
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const Logo = styled('img')({ ...center, top: '25%' });

const Loader = styled(ReactLoader)({
  ...center,
  top: '50%',
});

export default function SplashPage() {
  const { splashPage } = useSelector((state) => state.appSettings);

  if (!splashPage) return null;

  return (
    <Root>
      <Logo src={logo} alt='Veregy' width={560} />
      <Loader
        type={'spinningBubbles'}
        color={'grey'}
        height={'8%'}
        width={'8%'}
      />
    </Root>
  );
}
