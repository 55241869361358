import { useState } from 'react';
import { useOutletContext } from 'react-router';
import { useResizeObserver, useDebouncedCallback } from '@react-hookz/web';

function useTableWidth(adjust) {
  const { toolbarRef } = useOutletContext();
  const [contentWidth, setContentWidth] = useState(0);
  const resizeObserverCallback = useDebouncedCallback(
    (e) => setContentWidth(e?.contentRect?.width ?? 0),
    [setContentWidth],
    100
  );
  useResizeObserver(toolbarRef, resizeObserverCallback);
  return contentWidth ? contentWidth - (48 + 32 + 1) + (adjust ?? 0) : 0;
}

export default useTableWidth;
