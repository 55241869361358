import { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';

export function useTrendColor() {
  const theme = useTheme();
  const getTrendColor = useCallback(
    (object_type) => {
      switch (object_type) {
        case 'binary':
          return theme.colors.green;
        case 'multistate':
          return theme.colors.orange;
        case 'analog':
          return theme.colors.purple;
        default:
          return undefined;
      }
    },
    [theme?.colors]
  );

  return getTrendColor;
}

export default useTrendColor;
