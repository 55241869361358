import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get } from 'lodash';

import {
  postOrganizationFolderAPI,
  deleteOrganizationFolderAPI,
  putOrganizationFolderAPI,
} from '../../api';
import { addToastr, types } from '../../store/toastr';

// Folders are fetched with files

export const postOrganizationFolder = createAsyncThunk(
  'uploads/postOrganizationFolder',
  async ({ orgId, data }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;

    const sites = getState().sites.sites;
    const orgName = getState().organization.default.name;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const { folders } = await postOrganizationFolderAPI(orgId, data);

      const addedTo = data.parent.startsWith('org:')
        ? orgName
        : sites.find((site) => site.relation_id === data.parent).name;

      dispatch(
        addToastr({
          title: 'File Uploaded!',
          type: types.success,
          message: `Added ${data.name} to ${addedTo}.`,
        })
      );

      return { folders };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to add folder',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteOrganizationFolder = createAsyncThunk(
  'uploads/deleteOrganizationFolder',
  async ({ orgId, folderId }, { dispatch, getState }) => {
    try {
      const prevFiles = getState().uploads.files;

      dispatch(showLoading());

      const { folders, deleted } = await deleteOrganizationFolderAPI(
        orgId,
        folderId
      );

      dispatch(
        addToastr({
          title: 'Folder Deleted!',
          type: types.success,
        })
      );

      const deletedFileIds = deleted
        .filter((item) => item.type === 'file')
        .map((file) => file.relation_id);
      const files = prevFiles.filter(
        (file) => !deletedFileIds.includes(file.relation_id)
      );

      return { folders, files };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to delete folder',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putOrganizationFolder = createAsyncThunk(
  'uploads/putOrganizationFolder',
  async ({ orgId, data }, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().uploads;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());

      const { folders } = await putOrganizationFolderAPI(
        orgId,
        data.relation_id,
        data
      );

      dispatch(
        addToastr({
          title: 'File Updated!',
          type: types.success,
        })
      );

      return { folders };
    } catch (err) {
      console.error(err);
      dispatch(
        addToastr({
          title: 'Failed to update folder',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
    } finally {
      dispatch(hideLoading());
    }
  }
);
