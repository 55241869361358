import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { adminStats as initialState } from '../initialState';

import { getAdminStats } from './_adminStats';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'adminStats',
  initialState,
  reducers: {
    clearAdminStats: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getAdminStats]);
  },
});

const { clearAdminStats } = actions;

// Export the reducer, either as a default or named export
export { clearAdminStats, getAdminStats };
export default reducer;
