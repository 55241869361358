import { createSlice } from '@reduxjs/toolkit';
import { thunkReducer } from '../thunkTemplate';
import { bulkEdit as initialState } from '../initialState';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'bulkEdit',
  initialState,
  reducers: {
    clearBulkEdit: () => ({ ...initialState }),
    setBulkSelected: (state, { payload }) => {
      const {
        selected,
        itemType,
        onDelete,
        onDuplicate,
        onEdit,
        onDownload,
        onMove,
      } = payload;
      return {
        ...state,
        selected,
        itemType: selected.length ? itemType : null,
        onDelete: selected.length ? onDelete : null,
        onDuplicate: selected.length ? onDuplicate : null,
        onEdit: selected.length ? onEdit : null,
        onDownload: selected.length ? onDownload : null,
        onMove: selected.length ? onMove : null,
      };
    },
  },
});

const { clearBulkEdit, setBulkSelected } = actions;

// Export the reducer, either as a default or named export
export { clearBulkEdit, setBulkSelected };
export default reducer;
