import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAlarmExclamation as faAlarmExclamationRegular,
  faAngleRight as faAngleRightRegular,
  faAngleUp as faAngleUpRegular,
  faBars as faBarsRegular,
  faCircleQuestion as faCircleQuestionRegular,
  faCircle as faCircleRegular,
  faEllipsisVertical as faEllipsisVerticalRegular,
  faLightbulb as faLightbulbRegular,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAlarmExclamation as faAlarmExclamationSolid,
  faArrowsRotate as faArrowsRotateSolid,
  faBell as faBellSolid,
  faBuilding as faBuildingSolid,
  faBuildings as faBuildingsSolid,
  faCalendarDay as faCalendarDaySolid,
  faCaretDown as faCaretDownSolid,
  faCheck as faCheckSolid,
  faCircle as faCircleSolid,
  faClipboard as faClipBoardSolid,
  faGripVertical as faGripVerticalSolid,
  faPen as faPenSolid,
  faRulerTriangle as faRulerTriangleSolid,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faAlarmExclamation as faAlarmExclamationLight,
  faAngleRight as faAngleRightLight,
  faArrowDownLeftAndArrowUpRightToCenter as faArrowDownLeftAndArrowUpRightToCenterLight,
  faArrowRightFromBracket as faArrowRightFromBracketLight,
  faArrowsMaximize as faArrowsMaximizeLight,
  faArrowsRotate as faArrowsRotateLight,
  faArrowDownToLine as faArrowDownToLineLight,
  faArrowToLeft as faArrowToLeftLight,
  faArrowUpRightFromSquare as faArrowUpRightFromSquareLight,
  faBell as faBellLight,
  faBuilding as faBuildingLight,
  faBuildingLock as faBuildingLockLight,
  faBuildings as faBuildingsLight,
  faCalendarWeek as faCalendarWeekLight,
  faChartLine as faChartLineLight,
  faChartNetwork as faChartNetworkLight,
  faChartSimple as faChartSimpleLight,
  faCheck as faCheckLight,
  faChevronDown as faChevronDownLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faCircleCheck as faCircleCheckLight,
  faCircleExclamation as faCircleExclamationLight,
  faCircle as faCircleLight,
  faCircleInfo as faCircleInfoLight,
  faCirclePlus as faCirclePlusLight,
  faCircleQuestion as faCircleQuestionLight,
  faClock as faClockLight,
  faCopy as faCopyLight,
  faDiagramProject as faDiagramProjectLight,
  faDownLeftAndUpRightToCenter as faDownLeftAndUpRightToCenterLight,
  faEllipsisVertical as faEllipsisVerticalLight,
  faEye as faEyeLight,
  faEyeSlash as faEyeSlashLight,
  faFile as faFileLight,
  faFileCsv as faFileCsvLight,
  faFileExcel as faFileExcelLight,
  faFileImage as faFileImageLight,
  faFilePdf as faFilePdfLight,
  faFilePowerpoint as faFilePowerpointLight,
  faFileWord as faFileWordLight,
  faFolder as faFolderLight,
  faFolderArrowUp as faFolderArrowUpLight,
  faFolders as faFoldersLight,
  faGear as faGearLight,
  faHouse as faHouseLight,
  faInputNumeric as faInputNumericLight,
  faLightbulb as faLightbulbLight,
  faLinkSimple as faLinkSimpleLight,
  faLocationArrow as faLocationArrowLight,
  faLock as faLockLight,
  faLockKeyhole as faLockKeyholeLight,
  faMagnifyingGlassMinus as faMagnifyingGlassMinusLight,
  faMinus as faMinusLight,
  faObjectGroup as faObjectGroupLight,
  faObjectsColumn as faObjectsColumnLight,
  faPalette as faPaletteLight,
  faPaperclip as faPaperclipLight,
  faPen as faPenLight,
  faPenToSquare as faPenToSquareLight,
  faPlus as faPlusLight,
  faRotateExclamation as faRotateExclamationLight,
  faSquare as faSquareLight,
  faSquareCheck as faSquareCheckLight,
  faSquareMinus as faSquareMinusLight,
  faSquarePlus as faSquarePlusLight,
  faTrashCan as faTrashCanLight,
  faTriangleExclamation as faTriangleExclamationLight,
  faObjectUngroup as faObjectUngroupLight,
  faUpRightAndDownLeftFromCenter as faUpRightAndDownLeftFromCenterLight,
  faUser as faUserLight,
  faUserGear as faUserGearLight,
  faUsers as faUsersLight,
  faUsersGear as faUsersGearLight,
  faXmark as faXmarkLight,
} from '@fortawesome/pro-light-svg-icons';

export default function initIcons() {
  library.add(
    faAlarmExclamationLight,
    faAlarmExclamationRegular,
    faAlarmExclamationSolid,
    faAngleRightLight,
    faAngleRightRegular,
    faAngleUpRegular,
    faArrowDownLeftAndArrowUpRightToCenterLight,
    faArrowDownToLineLight,
    faArrowRightFromBracketLight,
    faArrowsMaximizeLight,
    faArrowsRotateLight,
    faArrowsRotateSolid,
    faArrowToLeftLight,
    faArrowUpRightFromSquareLight,
    faBarsRegular,
    faBellLight,
    faBellSolid,
    faBuildingLight,
    faBuildingLockLight,
    faBuildingSolid,
    faBuildingsLight,
    faBuildingsSolid,
    faCalendarDaySolid,
    faCalendarWeekLight,
    faCaretDownSolid,
    faChartLineLight,
    faChartNetworkLight,
    faChartSimpleLight,
    faCheckLight,
    faCheckSolid,
    faChevronDownLight,
    faChevronLeftLight,
    faChevronRightLight,
    faCircleLight,
    faCircleRegular,
    faCircleSolid,
    faCircleCheckLight,
    faCircleExclamationLight,
    faCircleInfoLight,
    faCirclePlusLight,
    faCircleQuestionLight,
    faCircleQuestionRegular,
    faClipBoardSolid,
    faClockLight,
    faCopyLight,
    faDiagramProjectLight,
    faDownLeftAndUpRightToCenterLight,
    faEllipsisVerticalLight,
    faEllipsisVerticalRegular,
    faEyeLight,
    faEyeSlashLight,
    faFileLight,
    faFileCsvLight,
    faFileExcelLight,
    faFileImageLight,
    faFilePdfLight,
    faFilePowerpointLight,
    faFileWordLight,
    faFolderLight,
    faFolderArrowUpLight,
    faFoldersLight,
    faGearLight,
    faGripVerticalSolid,
    faHouseLight,
    faInputNumericLight,
    faLightbulbLight,
    faLightbulbRegular,
    faLinkSimpleLight,
    faLocationArrowLight,
    faLockKeyholeLight,
    faLockLight,
    faMagnifyingGlassMinusLight,
    faMinusLight,
    faObjectGroupLight,
    faObjectsColumnLight,
    faPaletteLight,
    faPaperclipLight,
    faPenLight,
    faPenSolid,
    faPenToSquareLight,
    faPlusLight,
    faRotateExclamationLight,
    faRulerTriangleSolid,
    faSquareLight,
    faSquareCheckLight,
    faSquareMinusLight,
    faSquarePlusLight,
    faTrashCanLight,
    faTriangleExclamationLight,
    faTriangleExclamation,
    faObjectUngroupLight,
    faUpRightAndDownLeftFromCenterLight,
    faUserLight,
    faUserGearLight,
    faUsersLight,
    faUsersGearLight,
    faXmarkLight
  );
}
