import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function lastMonth(offset = 0) {
  // adjust start and end of previous month with offset
  let start = dayjs
    .utc()
    .subtract(1, 'month')
    .startOf('month')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs
    .utc()
    .subtract(1, 'month')
    .endOf('month')
    .subtract(offset, 'hour')
    .valueOf();

  return {
    key: 0,
    value: 'last_month',
    label: 'Last Month',
    start,
    end,
  };
}

function thisMonth(offset = 0) {
  // adjust start and end current Month with offset
  let start = dayjs.utc().startOf('month').subtract(offset, 'hour').valueOf();
  let end = dayjs.utc().endOf('month').subtract(offset, 'hour').valueOf();

  return {
    key: 1,
    value: 'this_month',
    label: 'This Month',
    start,
    end,
  };
}

function lastWeek(offset = 0) {
  // adjust start and end of previous week with offset
  let start = dayjs
    .utc()
    .subtract(1, 'week')
    .startOf('week')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs
    .utc()
    .subtract(1, 'week')
    .endOf('week')
    .subtract(offset, 'hour')
    .valueOf();

  return {
    key: 2,
    value: 'last_week',
    label: 'Last Week',
    start,
    end,
  };
}

function thisWeek(offset = 0) {
  // adjust start and end of current week with offset
  let start = dayjs.utc().startOf('week').subtract(offset, 'hour').valueOf();
  let end = dayjs.utc().endOf('week').subtract(offset, 'hour').valueOf();

  return {
    key: 3,
    value: 'this_week',
    label: 'This Week',
    start,
    end,
  };
}

function yesterday(offset = 0) {
  // adjust start and end of previous day with offset
  let start = dayjs
    .utc()
    .subtract(1, 'day')
    .startOf('day')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs
    .utc()
    .subtract(1, 'day')
    .endOf('day')
    .subtract(offset, 'hour')
    .valueOf();

  return {
    key: 4,
    value: 'yesterday',
    label: 'Yesterday',
    start,
    end,
  };
}

function today(offset = 0) {
  // utc start and end of day
  let start = dayjs.utc().startOf('day').subtract(offset, 'hour').valueOf();
  let end = dayjs.utc().endOf('day').subtract(offset, 'hour').valueOf();

  return {
    key: 5,
    value: 'today',
    label: 'Today',
    start,
    end,
  };
}

function last24Hours() {
  let start = dayjs.utc().subtract(24, 'hour').valueOf();
  let end = dayjs.utc().valueOf();

  return {
    key: 6,
    value: 'last_24_hours',
    label: 'Last 24 Hours',
    start,
    end,
  };
}

function last7Days(offset = 0) {
  let start = dayjs
    .utc()
    .startOf('day')
    .subtract(7, 'day')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs.utc().valueOf();

  return {
    key: 7,
    value: 'last_7_days',
    label: 'Last 7 Days',
    start,
    end,
  };
}

function last30Days(offset = 0) {
  let start = dayjs
    .utc()
    .startOf('day')
    .subtract(30, 'day')
    .subtract(offset, 'hour')
    .valueOf();
  let end = dayjs.utc().valueOf();

  return {
    key: 8,
    value: 'last_30_days',
    label: 'Last 30 Days',
    start,
    end,
  };
}

const generateRanges = (allowCustomRange, offset) => {
  let ranges = [
    lastMonth,
    thisMonth,
    lastWeek,
    thisWeek,
    yesterday,
    today,
    last24Hours,
    last7Days,
    last30Days,
  ].map((f) => f(offset));

  if (allowCustomRange === true)
    ranges.push({
      key: 9,
      value: 'custom',
      label: 'Custom Range',
      start: dayjs.utc().startOf('month').subtract(offset, 'hour').valueOf(),
      end: dayjs.utc().endOf('day').subtract(offset, 'hour').valueOf(),
    });

  return ranges;
};

function dayRange(dayObject, offset = 0) {
  let start = dayObject.startOf('day').subtract(offset, 'hour').valueOf();
  let end = dayObject.endOf('day').subtract(offset, 'hour').valueOf();
  return { start, end };
}

const getLatestInterval = () => {
  let now = dayjs();
  return now
    .minute(now.minute() - (now.minute() % 15))
    .second(0)
    .millisecond(0);
};

const dateStringToDayJS = (dateString) => {
  return dayjs(dateString?.substring(0, 19) + dateString?.slice(-6));
};

const convertTimestampOffset = (timestamp, offset = 0, newOffset = 0) => {
  const offsetDifference = newOffset - offset;
  return dayjs.utc(timestamp).subtract(offsetDifference, 'hour').valueOf();
};

export {
  lastMonth,
  thisMonth,
  lastWeek,
  thisWeek,
  yesterday,
  today,
  last24Hours,
  last7Days,
  last30Days,
  generateRanges,
  dayRange,
  getLatestInterval,
  dateStringToDayJS,
  convertTimestampOffset,
};
