import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { orgStats as initialState } from '../initialState';
import { getOrganizationEventsStatsAPI } from '../../api';

const getOrganizationEventsStats = createAsyncThunk(
  'orgStats/getOrganizationEventsStats',
  async (org, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading } = getState().orgStats;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const eventsStats = await getOrganizationEventsStatsAPI(org.item_id);
      return { eventsStats };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { actions, reducer } = createSlice({
  name: 'orgStats',
  initialState,
  reducers: {
    clearOrgStats: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getOrganizationEventsStats]);
  },
});

const { clearOrgStats } = actions;

// Export the reducer, either as a default or named export
export { clearOrgStats, getOrganizationEventsStats };
export default reducer;
